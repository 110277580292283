import Vue from 'vue'
import Vuex from 'vuex'

import init from '@/store/modules/init'
import user from '@/store/modules/user'
import filter from '@/store/modules/filter'
import languages from "@/store/modules/languages"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    init: init,
    user: user,
    filter: filter,
    languages: languages
  }
})

export default store
