<template>
  <div class="page-single" v-if="page">
    <h1 class="page-single-title color-blue text-center" v-html="page.title" />
    <div class="page-single-content" v-html="page.content" />
  </div>
</template>
<script>
export default {
  name: 'Page',
  metaInfo () {

    let title = ''

    if (this.page) {
      title = this.page.title
    }

    return {
      title: title
    }
  },
  asyncComputed: {
    page: {
      get () {
        let slug = this.$route.params.slug
        if (slug) {
          return this.$sdk.page.find(slug)
              .then(response => response.data)
        }
      }
    }
  }
}
</script>
