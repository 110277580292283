<template>
  <div v-scroll-lock="true">
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$emit('close')" class="vm--close">
    </div>

    <div class="modal-filter">

      <div class="campaign-filter">

        <ul class="campaign-filter-list">
          <li @click="changeTab(false)" :class="{'active': status === false}">
            <label>
              <span>{{ $t('filter.All') }}</span>
              <icon-close color="#0182FF" width="16" height="16" class="close"/>
            </label>
          </li>
          <li @click="changeTab('NEW')" :class="{'active': status === 'NEW'}">
            <label>
              <span>{{ $t('filter.New')  }}</span>
              <icon-close color="#0182FF" width="16" height="16" class="close"/>
            </label>
          </li>
          <li @click="changeTab('POPULAR')" :class="{'active': status === 'POPULAR'}">
            <label>
              <span>{{ $t('filter.Popular') }}</span>
              <icon-close color="#0182FF" width="16" height="16" class="close"/>
            </label>
          </li>
          <li @click="changeTab('FINISHED')" :class="{'active': status === 'FINISHED'}">
            <label>
              <span>{{ $t('filter.Closed') }}</span>
              <icon-close color="#0182FF" width="16" height="16" class="close"/>
            </label>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import IconClose from "@/assets/icons/IconClose";
import { mapState } from 'vuex'

export default {
  name: 'CampaignFilter',
  components: {
    IconClose
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    ...mapState({
      status: state => state.filter.status
    })
  },
  methods: {
    changeTab (val) {
      this.$store.dispatch('setFilterStatus', val)
    }
  }
}
</script>

<style lang="less">
.modal-filter {
  margin: 20px 0;
  max-width: 640px;
}
</style>
