import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sdk from '@/components/sdk'
import store from '@/store/index'

Vue.use(sdk)
Vue.use(VueI18n)

const messages = []

export const VUE_ACTIVE_LANG = store.getters.getActiveLanguage

export const i18n = new VueI18n({
  locale: VUE_ACTIVE_LANG, // установка локализации
  messages: messages,  // установка сообщений локализации
  silentTranslationWarn: true
})

const loadedLanguages = [] // список локализаций, которые пред-загружены

function setI18nLanguage(lang) {
  return i18n.locale = lang
}

export function setLanguage (lang) {
  return Vue.sdk.translate.setLanguage(lang).then (() => {
    return loadLanguageAsync(lang)
  })
}

export function loadLanguageAsync(lang) {
  // Если локализация уже была загружена
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // Если еще не подгружены
  return Vue.sdk.translate.messages(lang).then(response => {
    const msgs = response.data

    i18n.setLocaleMessage(lang, msgs)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}
