<template>
  <div class="wrap">
    <div class="container">

      <div class="block block-main" style="max-width: 700px;">
        <h1 class="block-title" v-html="$t('home.title')">
        </h1>
        <h3 class="block-description color-text-desc" style="color: red">
          {{ $t('home.description') }}
        </h3>

        <div class="block-content text-center">
          <router-link tag="button" :to="{name: $store.getters.isUser ? 'CampaignCreate': 'Registration'}" class="btn btn-primary btn-create">
            <img src="/images/icons_svg/ic_logo.svg" alt="">
            {{ $t('home.Create campaign') }}
          </router-link>
        </div>
      </div>

    </div>

    <div class="container-slider-fluid hidden-xs">
      <div class="container">
        <slider />
      </div>
    </div>

    <div class="container">

      <div class="block block-campaigns">
        <div class="block-content">

          <campaigns-filter />

          <div class="campaigns-grid" v-if="campaigns.length">
            <router-link :to="{name: 'Campaign', params: {id: campaign.id}}" tag="div" class="campaign campaign-scalable" v-for="(campaign, i) in campaigns" v-bind:key="i">
              <img class="campaign-image"
                   :src="getImageUrl(campaign.images[0], 600, 434)"
                   :srcset="getImageUrl(campaign.images[0], 1200, 868) + ' 2x'"
                   :alt="campaign.title"
              >
              <div class="campaign-data">
                <div class="campaign-category">
                  <span :class="getCampaignMarkerColor(campaign)">{{ getCampaignMarkerLabel(campaign) }}</span>
                </div>
                <h3 class="campaign-title">{{ campaign.title }}</h3>
                <p class="campaign-description">
                  {{ campaign.short_description }}
                </p>

                <div class="campaign-numbers">
                  <div class="campaign-numbers-left"><b>{{ campaign.collected_amount | numeral }}</b> EUR</div>
                  <div class="campaign-numbers-right" v-html="$t('campaign.Raised of <b>{count}</b> {currency}', {count: $options.filters.numeral(campaign.required_amount), currency: 'EUR'})" />
                </div>
                <div class="campaign-timeline">
                  <div class="campaign-timeline-bar" :style="{width: ((100 * campaign.collected_amount) / campaign.required_amount)  + '%'}"></div>
                </div>

                <div class="campaign-author" v-if="campaign.author">
                  <div class="campaign-author-image">
                    <img :src="getAuthorAvatar(campaign.author.avatarUrl)"/>
                  </div>
                  <div class="campaign-author-name">
                    {{ campaign.author.user_name }}
                  </div>
                  <div class="campaign-author-count">
                    {{ $tc('campaign.{count} payments', campaign.payment_count) }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <no-result v-if="!campaigns.length"></no-result>

          <button class="btn btn-primary btn-more" :class="{loading: loading}" v-if="this.pagination.curPage < this.pagination.pageCount" @click="makeRequest(this)">
            {{ $t('campaign-list.Show more') }}
          </button>

        </div>
      </div>

      <div class="block block-benefits">
        <h2 class="block-title" v-html="$t('home-benefits.title')" />
        <div class="block-content">

          <div class="benefit-row">
            <div class="benefit">
              <div class="benefit-icon">
                <img src="/images/img/raising.png" srcset="/images/img/raising@3x.png 2x"  alt="">
              </div>
              <div class="benefit-title">{{ $t('home-benefits.first-title') }}</div>
              <div class="benefit-desc">
                {{ $t('home-benefits.first-text') }}
              </div>
            </div>

            <div class="benefit">
              <div class="benefit-icon">
                <img src="/images/img/widget.png" srcset="/images/img/widget@3x.png 2x" alt="">
              </div>
              <div class="benefit-title">{{ $t('home-benefits.second-title') }}</div>
              <div class="benefit-desc">
                {{ $t('home-benefits.second-text') }}
              </div>
            </div>

            <div class="benefit">
              <div class="benefit-icon">
                <img src="/images/img/quick.png" srcset="/images/img/quick@3x.png 2x" alt="">
              </div>
              <div class="benefit-title">{{ $t('home-benefits.third-title') }}</div>
              <div class="benefit-desc">
                {{ $t('home-benefits.third-text') }}
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="block block-hiw center-block">
        <h2 class="block-title" v-html="$t('hiw-block.title')"></h2>
        <h3 class="block-description color-dark-blue">{{ $t('hiw-block.description') }}</h3>

        <div class="block-content">
          <div class="block-inside">
            <div class="block-inside-icon">
              <img src="/images/img/launch@3x.png" width="108" height="117px" style="margin-top: 29px; margin-left: 55px;"/>
            </div>
            <div class="block-inside-title">
              {{ $t('hiw-block.Launch campaign') }}
            </div>
            <div class="block-inside-text">
              •  {{ $t('hiw-block.Set a fundraising goal') }} •  {{ $t('hiw-block.Tell your story') }} •  {{ $t('hiw-block.Add photos and videos') }}
            </div>
          </div>
          <div class="block-inside">
            <div class="block-inside-icon">
              <img src="/images/img/campaign@3x.png" width="110" height="103" style="margin-left: -20px;margin-top: 30px;"/>
            </div>
            <div class="block-inside-title">
              {{ $t('hiw-block.Spread the word campaign') }}
            </div>
            <div class="block-inside-text">
              •  {{ $t('hiw-block.Tell your friends about your campaign') }} •  {{ $t('hiw-block.Share your campaign in social media') }}
            </div>
          </div>
          <div class="block-inside">
            <div class="block-inside-icon">
              <img src="/images/img/transfer@3x.png" width="129" height="116px" style="margin-top: 29px; margin-left: 60px;"/>
            </div>
            <div class="block-inside-title">
              {{ $t('hiw-block.Get transfers from backers') }}
            </div>
            <div class="block-inside-text">
              •  {{ $t('hiw-block.Create a Stripe account and accept payments on GetDonate') }}  •  {{ $t('hiw-block.Withdraw funds directly to your bank account') }}
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import slider from '@/components/Slider';
import NoResult from "@/views/blocks/NoResults";
import {mapState} from 'vuex';
import CampaignMixin from "@/components/mixins/CampaignMixin";
import CampaignsFilter from '@/components/CampaignsFilter'

export default {
  name: 'Home',
  mixins: [CampaignMixin],
  components: {
    NoResult,
    slider,
    CampaignsFilter
  },
  metaInfo () {
    return {
      title: this.$t('home.page-title')
    }
  },
  data () {
    return {
      loading: false,
      status: false,

      campaigns: [],
      filter: {},

      pagination: {
        totalCount: undefined,
        pageCount: undefined,
        curPage: 0
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    filterCategory () {
      return this.$route.params['slug'] || 'all'
    },
    filterStatus() {
      return this.$route.params['status']
    }
  },
  created () {
    if (this.$route.name === 'VerifyEmail') {
      this.$sdk.auth.verifyEmail(this.$route.params.token).then(response => {
        if (response.data.result) {
          this.$toasted.info(this.$t('verify-email.message'))
          this.$sdk.user.me()
        }
      }).catch(reason => {
        this.$toasted.error(reason.response.data.message)
        this.$router.push({name: 'Home'})
      })
    }

    this.makeRequest()

    // Process login with social network
    if (this.$route.name === 'SocialAuth') {
      this.$sdk.auth.social(this.$route.query)
        .then(response => {

          // If error
          if (response.data.error) {
            this.$toasted.error(response.data.error)
          } else {
            this.$sdk.user.me().then(() => {
              // Default route
              let route = {name: 'Home'}

              // If account linked
              if (response.data.linked) {
                this.$toasted.info(this.$t('social-auth.message_linked'))
                if (response.data.id === 'stripe') {
                  route = {name: 'AccountIntegrations'}
                } else {
                  route = {name: 'AccountProfile'}
                }
              }

              // If login
              if (response.data.login) {
                this.$toasted.info(this.$t('social-auth.message_logged'))
              }

              this.$router.push(route)
            })
          }
        })
        .catch(reason => {
          let resData = reason.response.data
          if (resData.message) {
            this.$toasted.error(resData.message)
          }
        })
    }
  },
  watch: {
    '$route' () {
      this.makeRequest(true)
    }
  },
  methods: {
    makeRequest (reset = false) {
      if (this.loading){
        return false
      }

      this.loading = true;

      const params = {
        'expand': 'author,category',
        'page': ++this.pagination.curPage
      }

      if (this.filterStatus && this.filterStatus.toLowerCase() !== 'all') {
        params.status = this.filterStatus.toUpperCase()
      }

      let cat_id = parseInt(this.filterCategory)
      if (cat_id > 0) {
        params.category_id = cat_id
      }

      return this.$sdk.campaign.find({
        params: params,
      }).then(response => {
        this.pagination.curPage = parseInt(response.headers['x-pagination-current-page'])
        this.pagination.pageCount = parseInt(response.headers['x-pagination-page-count'])
        this.pagination.totalCount = parseInt(response.headers['x-pagination-total-count'])

        if (reset) {
          this.pagination.curPage = 1
          this.campaigns = []
        }

        for (let campaign of response.data) {
          this.campaigns.push(campaign)
        }

        this.loading = false;
      })
    },
    getRandomImage () {
      return 'https://picsum.photos/550?v=' + Math.random();
    },
    getImageUrl (url, width, height) {

      url = url.replace('{w}', width)
      url = url.replace('{h}', height)

      return url
    },

    changeTab (tab_index) {
      this.status = tab_index
      this.makeRequest(true)
    },

    getAuthorAvatar (str = '') {

      if (str) {
        str = str.replace('{w}', 90)
        str = str.replace('{h}', 90)
      }

      return str
    }
  }
}
</script>
