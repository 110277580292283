<template>
  <div class="registration-success">
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$emit('close')" class="vm--close">
    </div>

    <h1 class="text-center" v-html="$t('registration-success.title')"/>

    <div class="text-center" style="margin-top: 60px">
      <img src="/images/mail.svg" :alt="$t('registration-success.Registration completed')">
    </div>

    <div class="text-center hidden-xs">
      <div class="other">
        {{ $t('registration-success.Any questions?') }} <a href="mailto:info@getdonate.org">{{ $t('registration-success.Contact right now') }}</a>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'RegistrationSuccess',
}
</script>

<style lang="less" scoped>
.delimiter {
  margin: 30px 0 10px;
  font-size: 12px;
  font-weight: 500;
  color: #7483A9;
}
.other {
  display: inline-block;
  margin: 60px auto 0;
  border-top: 1px solid rgba(116, 131, 169, .3);

  color: rgba(116, 131, 169, .7);
  padding: 36px 0 19px;
}

@media (max-width: 1000px) {
  .registration-success h1 {
    font-size: 24px;
    line-height: 38px;
  }
}

</style>
