<template>
  <div>
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$emit('close')" class="vm--close">
    </div>

    <div class="registration-form" v-scroll-lock="true">
      <h1 class="registration-form-title color-dark-blue">{{ $t('reset-password-modal.title') }}</h1>
      <h4 class="registration-form-description color-text-desc">{{ $t('reset-password-modal.Already registered?') }} <router-link :to="{name: 'Login'}">{{ $t('reset-password-modal.Sign in') }}</router-link></h4>

      <form class="registration-form-content" @submit.prevent="submit()">
        <div class="left-block">

          <form-field :label="$t('reset-password-modal.email-label')" :value="form.email" :error="errors.email">
            <input type="text" v-model="form.email" @keyup="validate(['email'])" class="form-control" :placeholder="$t('reset-password-modal.email-placeholder')"/>
          </form-field>

          <button type="submit" class="btn btn-primary" :disabled="!form.email">{{ $t('reset-password-modal.sign-in-button-text') }}</button>
        </div>
      </form>

    </div>

    <div class="other hidden-xs">
      {{ $t('reset-password-modal.Any questions?') }} <a href="mailto:info@getdonate.org">{{ $t('reset-password-modal.Contact right now') }}</a>
    </div>

  </div>
</template>

<script>
import FormField from '@/components/FormField';

export default {
  name: 'ResetPassword',
  data () {
    return {
      form: {
        email: undefined
      },

      errors: {}
    }
  },
  computed: {
    formData () {
      return {
        email: this.form.email
      }
    }
  },
  components: {
    FormField
  },
  methods: {

    validate (attributes = []) {
      let form = this.formData
      this.$sdk.auth.resetPassword(form, true).then(response => {
        for (let attribute of attributes) {
          if (response.data[attribute] !== undefined) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    },
    submit () {
      let form = this.formData
      this.$sdk.auth.resetPassword(form).then(response => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$emit('close')
          this.$toasted.info('reset-password.verification-link-was-sent')
        } else {
          this.errors = response.data
        }
      })
    }
  }
}
</script>
