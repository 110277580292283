<template>
  <div>
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$router.go(-1)" class="vm--close">
    </div>

    <div class="payments-form" v-scroll-lock="true">
      <h1 class="payments-form-title color-dark-blue">{{ $t('payments-modal.title') }}</h1>

      <div class="payments-form-content">
        <no-results v-if="data.length === 0" class="color-dark-blue" :text="$t('payments-modal.Payments not found')" />

        <table v-else class="payments-grid">
          <thead>
          <tr>
            <th>{{ $t('payments-modal.Amount (EUR)') }}</th>
            <th>{{ $t('payments-modal.Date') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="payment-item" v-for="payment in this.data" v-bind:key="payment.id">
            <td class="payment-item-amount">{{ payment.amount | numeral }}</td>
            <td class="payment-item-date">{{ payment.date | moment("DD-MM-YYYY, HH:ss") }}</td>
          </tr>
          </tbody>
        </table>

        <div class="text-center" v-if="buttonEnabled">
          <button type="button" class="btn btn-primary" @click="loadMore">{{ $t('payments-modal.Show more') }}</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NoResults from "@/views/blocks/NoResults";

export default {
  name: 'Payments',
  components: {
    NoResults
  },
  data () {
    return {
      busy: false,
      data: [],
      page: 1,
      pageCount: 1,
      buttonEnabled: false
    }
  },
  created() {
    this.loadMore()
  },
  methods: {
    loadMore () {
      this.busy = true
      this.$sdk.campaign.payments(this.$route.params.id, {
        params: {
          page: this.page
        }
      })
        .then(response => {
          this.busy = false

          for (let item of response.data) {
            this.data.push(item)
          }

          this.pageCount = parseInt(response.headers['x-pagination-page-count'])
          this.page = parseInt(response.headers['x-pagination-current-page'])

          if (this.pageCount > this.page ) {
            this.page++
            this.buttonEnabled = true
          } else {
            this.buttonEnabled = false
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>

.payments-form {
  &-title {
    margin-bottom: 15px;
  }

  &-description {
    margin-bottom: 35px;
    font-size: 12px;

    a {
      font-size: 14px;
    }
  }

  &-content {
    margin-left: -60px;
    margin-right: -60px;

    max-height: 60vh;
    overflow: scroll;

    .payments-grid {
      width: 100%;
      max-width: 700px;

      @media (max-width: 1200px) {
        width: 100%;
        max-width: 100%;
      }

      td,
      th {
        border-top: 1px solid rgba(45,67,121,0.13);
        text-align: left;

        &:first-child {
          padding-left: 55px;
          width: 99%;
        }

        &:last-child {
          padding-right: 55px;
          width: 1%;
        }
      }

      th {
        color: #7483A9;
        padding: 18px 33px;
        border-top: none;
        font-size: 12px;
        font-weight: 500;
      }

      td {
        color: #2D4379;
        padding: 33px;
        white-space: nowrap;
      }
    }


  }
}

</style>
