<template>
  <form @submit.prevent="submit" class="news-form">
    <form-field label="Title news" :error="errors.title" :value="form.title">
      <input type="text" :placeholder="$t('campaign-news-form.title-placeholder')" @keyup="validate(['title'])" v-model="form.title" class="form-control" />
    </form-field>

    <form-field label="Description" :error="errors.description" :value="form.description">
      <textarea :placeholder="$t('campaign-news-form.description-placeholder')" @keyup="validate(['description'])" v-model="form.description" class="form-control news-form-textarea" />
    </form-field>

    <div class="text-right" v-if="isButtonsVisible">
      <button type="button" class="btn btn-inline btn-white btn-quarter" style="margin-right: 10px" @click="reset">
        <icon-close color="#FF4C54" />
      </button>
      <button type="submit" class="btn btn-inline btn-white btn-quarter">
        <icon-checkbox color="#23AE8D" />
      </button>
    </div>

  </form>
</template>

<script>
import FormField from '@/components/FormField';
import IconClose from "@/assets/icons/IconClose";
import IconCheckbox from "@/assets/icons/IconCheckbox";

export default {
  name: 'QuestionForm',
  props: {
    /**
     * Campaign id
     */
    id: {
      type: Number,
      default: 0
    },
    newsId : {
      type: Number,
      default: 0
    }
  },
  components: {
    IconCheckbox,
    IconClose,
    FormField,
  },
  data () {
    return {
      form: {
        title: '',
        description: '',
      },

      errors: {}
    }
  },
  computed: {
    isButtonsVisible () {
      return this.form.title || this.form.description
    },
    isNewRecord () {
      return this.newsId === 0
    }
  },
  asyncComputed: {
    article () {
      if (this.newsId) {
        return this.$sdk.news.view(this.newsId)
          .then(response => {
            this.form.title = response.data.title
            this.form.description = response.data.description

            return response.data
          })
      }
    }
  },
  methods: {
    validate (attrs = []) {
      let promise = null

      if (this.isNewRecord) {
        promise = this.$sdk.news.create(this.id, this.form, true)
      } else {
        promise = this.$sdk.news.update(this.newsId, this.form, true)
      }

      promise.then(response => {
        for (let attribute of attrs) {
          if (response.data[attribute]) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    },
    submit () {
      let promise = null

      if (this.isNewRecord) {
        promise = this.$sdk.news.create(this.id, this.form, false)
      } else {
        promise = this.$sdk.news.update(this.newsId, this.form, false)
      }

      promise.then(response => {
        if (Array.isArray(response.data)) {

          let mess = ''
          if (this.isNewRecord) {
            mess = this.$t('campaign-news-form.News was created')
          } else {
            mess = this.$t('campaign-news-form.News was updated')
          }
          this.$toasted.info(mess)

          this.$emit('update-news')
          this.reset()
        } else {
          this.errors = response.data
        }
      })
    },
    reset () {
      this.form.title = ''
      this.form.description = ''

      this.errors = {}

      this.$emit('reset')
    }
  }
}
</script>

<style lang="less" scoped>
.news-form {
  margin-top: 30px;

  &-textarea {
    min-width: 100%;
    max-width: 100%;

    min-height: 110px;
  }
}
</style>
