export default {
  data : function () {
    return {
      campaignMarkerClass: {
        'DRAFT': 'color-violet',
        'CHECK': 'color-yellow',
        'FINISHED': 'color-yellow',
        'POPULAR': 'color-green',
        'NEW': 'color-violet',
        'WAITING': 'color-yellow'
      },
      campaignMarkerLabel: {
        'DRAFT': 'DRAFT',
        'CHECK': 'CHECKING',
        'FINISHED': 'FINISHED',
        'POPULAR': 'POPULAR',
        'NEW': 'NEW',
        'WAITING': 'WAITING'
      },
    }
  },
  methods: {
    getCampaignMarkerColor (campaign) {
      if (this.campaignMarkerClass[campaign.status]) {
        return this.campaignMarkerClass[campaign.status]
      } else {
        return ''
      }
    },
    getCampaignMarkerLabel (campaign) {
      if (this.campaignMarkerLabel[campaign.status]) {
        return this.campaignMarkerLabel[campaign.status]
      } else {
        return ''
      }
    }
  }
}
