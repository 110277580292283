<template>
  <div class="link-social-wrp">
    <div class="form-group">
      <div class="control-label">{{ label }}</div>

      <div class="link-social">
        <button type="button" :class="{disabled: user.social.facebook, unlink: user.social.facebook}" @click="login('facebook')">
          <img src="/images/socials/facebook.svg">
          <span>{{ $t('link_profile.unlink') }}</span>
        </button>
        <button type="button" :class="{disabled: user.social.google, unlink: user.social.google}" @click="login('google')">
          <img src="/images/socials/gmail.svg">
          <span>{{ $t('link_profile.unlink') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LinkProfile',
  props: {
    label: {
      type: String,
      default () {
        return this.$t('link_profile.Link your social networks')
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  asyncComputed: {
    clients() {
      return this.$sdk.auth.clients()
          .then(response => response.data)
    },
  },
  methods: {
    login (name) {
      /**
       * If already linked
       */
      if (this.user.social[name]) {
        this.$sdk.auth.socialDelete(name).then(resp => {
          this.$sdk.user.me()

          let message = this.$t('link_profile.You have successfully untied {name}', {name: name})
          this.$toasted.info(message)

          return resp
        })

        return
      }

      /**
       * Id user want to link with social network
       */
      if (this.clients[name]) {
        window.location.href = this.clients[name]
      }
    }
  }
}
</script>

<style lang="less">
.link-social {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  button {
    border: 1px solid rgba(49, 55, 69, 0.13);
    border-radius: 9px;
    background-color: #FFFFFF;
    padding: 14px;
    box-sizing: border-box;

    width: 100%;

    span {
      display: none;
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      border-color: #0182FF;
      box-shadow: 0 11px 13px 0 rgba(45, 67, 121, 0.13);
    }

    &.disabled,
    &:disabled
    {
      background: #fdfdfd;
      opacity: .7;
      transition: all .3s linear;

      &:hover {
        //cursor: default;
        border-color: rgba(49, 55, 69, 0.13);
        box-shadow: none;
      }
    }

    &.unlink {
      &:hover {
        background: #FF4C54;
        color: #fff;
        content: 'Unlink';

        span {
          display: block;
        }

        img {
          display: none;
        }
      }
    }
  }

  &-wrp {
    text-align: left;
  }
}
</style>
