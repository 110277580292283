export default {
  state: {
    user: null
  },
  mutations: {
    user (state, data) {
      state.user = data
    }
  },
  actions: {
    setUser ({ commit }, data) {
      commit('user', data)
    }
  },
  getters: {
    isUser: (state) => { return !!state.user }
  }
}
