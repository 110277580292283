<template>
  <div class="container">
    <div class="container-wrap">
      <h1 class="text-center profile-title" v-html="$t('account.title')" />

      <ul class="campaign-tabs mini center-block">
        <router-link :to="{name: 'AccountCampaigns'}" tag="li" active-class="active">
          <span>{{ $t('account.My Campaigns') }} <i class="marker" v-if="count > 0">{{ count }}</i></span>
        </router-link>
        <router-link :to="{name: 'AccountProfile'}" tag="li" active-class="active" class="icon-edit">
          <span>{{ $t('account.My Profile') }} <icon-edit height="18" width="18" :color="iconEditColor"/></span>
        </router-link>
        <router-link :to="{name: 'AccountIntegrations'}" tag="li" active-class="active">
          <span>
            <i class="icon-integrations" :class="{inc: !this.isStripeIntegrated}">{{ $t('account.My Integrations') }}</i>
          </span>
        </router-link>
      </ul>

      <div class="content">
        <transition name="fade">
          <router-view/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconEdit from '@/assets/icons/IconEdit';

export default {
  name: 'Account',
  components: {
    IconEdit
  },
  metaInfo () {
    return {
      title: this.$t('account.page_title')
    }
  },
  data () {
    return {
      count: 0
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    iconEditColor () {
      return this.$route.path === '/account/profile' ? '#0182FF' : '#7483A9'
    },
    isStripeIntegrated () {
      return this.user && this.user.social && this.user.social.stripe
    }
  },
  created () {
    this.countMyCampaigns()
  },
  methods: {
    countMyCampaigns() {
      this.$sdk.campaign.find({
        params: {
          author_id: this.user.id,
          is_public: 0
        }
      }).then(response => {
        this.count = response.headers['x-pagination-total-count']
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .profile-title {
    margin-bottom: 30px;

    @media (max-width: 1000px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .campaign-tabs {
    margin-top: 30px;

    .icon-edit {
      span {
        padding-right: 65px;
        position: relative;
      }

      svg {
        position: absolute;
        top: 12px;
        right: 34px;
      }
    }
  }

  .content {
    max-width: 640px;
    width: 100%;
    margin: 60px auto 40px;
    padding-bottom: 40px;
  }

  .container-wrap {
    margin: 0 auto;
  }

  .icon-integrations {
    position: relative;

    &.inc:after {
      display: block;
      content: ' ';
      height: 10px;
      width: 10px;
      position: absolute;
      box-sizing: border-box;
      top: -3px;
      right: -10px;

      background: #FF4C54;
      border-radius: 50%;
      border: 2px solid #FFF;
    }
  }
</style>
