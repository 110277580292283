<template>
  <div class="container" style="margin-bottom: 40px;">
    <h1 class="campaign-create-title text-center" v-html="$t('campaing-create.title')"></h1>

    <campaign-form />

  </div>
</template>

<script>
import CampaignForm from "@/views/campaign/CampaignForm";

export default {
  name: 'CampaignCreate',
  components: {
    CampaignForm
  },
  metaInfo() {
    return {
      title: this.$t('campaing-create.header-title')
    }
  }
}
</script>
