export default {
  state: {
    /**
     * List of all available categories
     */
    categories: [],
    statuses: [],

    /**
     * Active items
     */
    category: false,
    status: false
  },
  mutations: {
    setCategory (state, data) {
      state.category = data
    },
    setCategories (state, data) {
      state.categories = data
    },
    setStatus (state, data) {
      state.status = data
    },
    setStatusList (state, data) {
      state.statuses = data
    }
  },
  actions: {
    setFilterCategory ({commit}, data) {
      commit('setCategory', data)
    },
    setFilterCategories ({commit}, data) {
      commit('setCategories', data)
    },
    setFilterStatus ({commit}, data) {
      commit('setStatus', data)
    },
    setFilterStatusList ({commit}, data) {
      commit('setStatusList', data)
    }
  }
}
