<template>
  <div class="no-results">
    <img class="no-results-imgn" src="/images/icons_svg/no-results.svg" alt="Search result is empty">
    <div class="no-results-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'NoResult',
  props: {
    text: {
      type: String,
      default () {
        return this.$t('campaign.No campaigns found')
      }
    }
  }
}
</script>

<style lang="less">
.no-results {
  text-align: center;
  margin: 0;
  //border: 1px solid rgba(0,0,0, .1);
  border-radius: 13px;
  padding: 60px;
  //box-shadow: 0 33px 66px 0 rgba(45, 67, 121, 0.13);

  &-imgn {
    position: relative;
    right: -30px;
  }
  &-text {
    color: #2D4379;
    font-weight: 500;
    margin-top: -70px;
  }
}

@media all and (max-width: 1200px) {
  .no-results {
    padding: 0 20px;

    &-imgn {
      max-width: 100%;
    }
  }
}
</style>
