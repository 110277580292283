<template>
  <div>
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click.prevent="close" class="vm--close">
    </div>
    <stripe :bordered="false" />
  </div>
</template>

<script>
import Stripe from "@/views/integrations/Stripe";
export default {
  name: 'StripeModal',
  components: {
    Stripe
  },
  methods: {
    close () {
      this.$router.push({name: 'MyCampaignShow', params: {id: this.$route.params.id}})
    }
  }
}
</script>

