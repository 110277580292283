import store from '../store'
import axios from 'axios'
import {setLanguage} from "@/i18n";

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

function getUrlLanguage () {
  let matches = document.location.pathname.match(/^\/(ru|en|by)\/?/)

  if (matches && matches[1]) {
    return matches[1]
  } else {
    return 'en'
  }
}

export default {
  install (Vue) {
    Vue.sdk = Vue.prototype.$sdk = {
      init () {
        return Promise.all([
          this.user.me(),
          setLanguage(getUrlLanguage()),
        ]).then(() => {
          store.dispatch('setInitialized', true)
        })
      },
      auth: {
        login (form, params) {
          return axios.post('/auth/login', form, {
            params: params
          })
        },
        logout () {
          return axios.post('/auth/logout').then(response => {
            store.dispatch('setUser', null);
            return response;
          })
        },
        resetPassword (form, validate = false) {
          return axios.post('/auth/reset-password', form, {
            params: {
              validate: validate ? 1 : 0
            }
          })
        },
        setNewPassword(token, form, validate = false) {
          return axios.post(`/auth/reset-password/${token}`, form, {
            params: {
              validate: validate ? 1 : 0
            }
          })
        },
        verifyEmail (token) {
          return axios.get(`/auth/verify-email/${token}`)
        },
        clients() {
          return axios.get('/auth/client')
        },
        social(params) {
          return axios.get('/auth/social', {
            params: params
          })
        },
        socialDelete (name) {
          return axios.delete(`/auth/client/${name}`)
        }
      },
      user: {
        me () {
          return axios.get('/user', {
            params: {
              expand: ['social'].join(',')
            }
          }).then(response => {
            store.dispatch('setUser', response.data);
            return response;
          })
        },
        create (form, validate = false) {
          return axios.post('/user', form, {
            params: {
              validate: validate ? 1 : 0
            }
          })
        },
        update (form, validate = false) {
          return axios.patch('/user', form, {
            params: {
              validate: validate ? 1 : 0
            }
          })
        },
        avatar (form) {
          return axios.post('/user/avatar', form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
      },
      campaign: {
        find (params) {
          return axios.get('/campaign', params)
        },
        view (id, params = {}) {
          return axios.get(`/campaign/${id}`, {
            params: params
          })
        },
        create (form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.post('/campaign', form, {
            params: params
          })
        },
        delete (id) {
          return axios.delete(`/campaign/${id}`)
        },
        update (id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.patch(`/campaign/${id}`, form, {
            params: params
          })
        },
        image (form) {
          return axios.post('/campaign/image', form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        },
        publish (id) {
          return axios.put(`/campaign/${id}`)
        },
        pay (id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.post(`/campaign/${id}/payment`, form, {
            params: params
          })
        },
        payments (id, json = {}) {
          return axios.get(`/campaign/${id}/payment`, json)
        }
      },
      question: {
        create (id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.post(`/campaign/${id}/question`, form, {
            params: params
          })
        },
      },
      news: {
        find (campaign_id, params = {}) {
          return axios.get(`/campaign/${campaign_id}/news`, {
            params: params
          })
        },
        create (campaign_id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.post(`/campaign/${campaign_id}/news`, form, {
            params: params
          })
        },
        update (id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.patch(`/campaign/news/${id}`, form, {
            params: params
          })
        },
        view (id, params = {}) {
          return axios.get(`/campaign/news/${id}`, {
            params: params
          })
        }
      },
      comment: {
        find (campaign_id, params = {}) {
          return axios.get(`/campaign/${campaign_id}/comment`, {
            params: params
          })
        },
        create (campaign_id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.post(`/campaign/${campaign_id}/comment`, form, {
            params: params
          })
        },
        update (id, form, validate = true) {
          let params = {
            validate: validate ? 1 : 0
          };

          return axios.patch(`/campaign/comment/${id}`, form, {
            params: params
          })
        },
        view (id, params = {}) {
          return axios.get(`/campaign/comment/${id}`, {
            params: params
          })
        }
      },
      category: {
        find () {
          return axios.get('/category')
        }
      },
      page: {
        find (slug = '') {
          return axios.get(`/page/${slug}`)
        }
      },
      translate: {
        languages () {
          return axios.get(`/translate/language`).then(response => {
            store.dispatch('setLanguages', response.data)
            return response
          })
        },
        messages (lang) {
          return axios.get(`/translate/${lang}.json`)
        },
        setLanguage (lang) {
          return axios.post('/translate/language', {id: lang})
        }
      }
    }
  }
}
