<template>
  <div class="">
    <stripe />
  </div>
</template>

<script>
import Stripe from '@/views/integrations/Stripe'
export default {
  name: 'AccountIntegrations',
  components: {
    Stripe
  }
}
</script>
