<template>
  <form id="profile-edit-form" @submit.prevent="submit" autocomplete="off">

    <div class="profile-avatar-link-block">
      <form-field :error="errors.avatar" :value="form.avatar" label=" ">
        <label class="file-upload">
          <input type="file" @change="fileUpload" class="file-upload-input">
          <div class="file-upload-block"><img :src="getUserAvatar(displayUrl)" :srcset="getUserAvatar(displayUrl, 132, 132)"></div>
          <a class="file-upload-link">{{ $t('profile.change-cover-text') }}</a>
        </label>
      </form-field>

      <link-profile />
    </div>

    <form-field :label="$t('profile.name-label')" :error="errors.user_name" :value="form.username">
      <input type="text" class="form-control" @keyup="validate(['user_name'])" v-model="form.username" :placeholder="$t('profile.name-placeholder')">
    </form-field>

    <form-field :label="$t('profile.email-label')" :error="errors.email" :value="form.email">
      <input type="email" class="form-control" @keyup="validate(['email'])" v-model="form.email" :placeholder="$t('profile.email-placeholder')">
    </form-field>

    <form-field :label="$t('profile.password-label')" :error="errors.password_repeat" :value="form.password_repeat">
      <input type="password" class="form-control" @keyup="validate(['password_repeat'])" v-model="form.password_repeat" :placeholder="$t('profile.password-placeholder')">
    </form-field>

    <form-field :label="$t('profile.password-repeat-label')" :error="errors.password" :value="form.password">
      <input type="password" class="form-control" @keyup="validate(['password'])" v-model="form.password" :placeholder="$t('profile.password-repeat-placeholder')">
    </form-field>

    <div class="form-group-links">

      <div class="form-group" v-for="(value, key) in socialLinks" v-bind:key="key">
        <label class="control-label">{{ $t('profile.social-links-label') }}</label>
        <input type="text" class="form-control" placeholder="Link to social network" v-model="socialLinks[key]">
        <a v-if="key === (socialLinks.length - 1)" class="add-social-link" @click="addSocialLink()">{{ $t('profile.social-add-link') }}</a>
        <a v-else class="remove-social-link" @click="removeSocialLink(key)">{{ $t('profile.social-remove-link') }}</a>
      </div>

    </div>

    <button type="submit" class="btn btn-primary" :disabled="!isSubmitActive">{{ $t('profile.save-changes') }}</button>

  </form>
</template>

<script>
import { mapState } from 'vuex';
import FormField from '@/components/FormField';
import LinkProfile from "@/views/blocks/LinkProfile";

export default {
  name: 'AccountProfile',
  components: {
    FormField,
    LinkProfile
  },
  data () {
    return {
      socialLinks: [],

      form: {
        username: '',
        email: '',
        avatar: '',
        password: '',
        password_repeat: '',
      },

      errors: {},

      /** Only for display avatar */
      displayUrl: '',
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    formData () {
      return {
        user_name: this.form.username,
        email: this.form.email,
        social_links: this.socialLinks,
        avatar: this.form.avatar,
        password: this.form.password,
        password_repeat: this.form.password_repeat
      }
    }
  },
  created () {
    this.form.username = this.user.user_name
    this.form.email = this.user.email
    this.form.avatar = this.user.avatar
    this.displayUrl = this.user.avatarUrl

    if (Array.isArray(this.user.social_links) && this.user.social_links.length > 0) {
      this.socialLinks = this.user.social_links
    } else {
      this.socialLinks = ['']
    }
  },
  methods: {
    basename(path) {
      return path.split('/').reverse()[0];
    },
    addSocialLink () {
      this.socialLinks.push('');
    },
    removeSocialLink (index) {
      this.socialLinks = this.socialLinks.filter((value, ind) => {
        return ind !== index
      })
    },

    isSubmitActive() {
      return this.form.email &&
          this.form.username &&
          this.form.avatar
    },

    /**
     * Render user avatar
     *
     * @param str
     * @param width
     * @param height
     * @return {*}
     */
    getUserAvatar (str, width = 57, height = 57) {
      if (str) {
        str = str.replace('{w}', width)
        str = str.replace('{h}', height)
      }

      return str
    },
    validate (attributes = []) {
      let form = this.formData

      this.$sdk.user.update(form, true).then(response => {
        for (let attribute of attributes) {
          if (response.data[attribute]) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    },

    submit () {
      let form = this.formData

      this.$sdk.user.update(form, false).then(response => {
        if (Array.isArray(response.data)) {
          this.$sdk.user.me().then(() => {
            this.$toasted.info(this.$t('profile.changes-was-saved'))
          })
        } else {
          this.errors = response.data
        }
      })
    },
    fileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const form = new FormData();
        form.append('file', file);

        this.form.avatar = null;

        this.$sdk.user.avatar(form).then(response => {
          if (response.data.error !== undefined) {
            this.errors.image = response.data.error
          } else {
            this.form.avatar = response.data.name
            this.displayUrl = response.data.url
          }
        }).finally(() => {
          this.validate(['image'])
        })
      }
    }

  }
}
</script>

<style lang="less">
.form-group-links {
  .form-group {
    position: relative;

    .control-label {
      display: none;
    }

    .add-social-link,
    .remove-social-link {
      bottom: 23px;
      position: absolute;

      font-size: 16px;
    }

    .remove-social-link {
      color: #FF4C54;
      right: -73px;

      &:after {
        background-color: #FF4C54;
      }
    }

    .add-social-link {
      bottom: 23px;
      right: -83px;
    }

    &:first-child {
      .control-label {
        display: block;
      }
    }
  }

  @media (max-width: 860px) {
    .form-group {
      width: calc(100% - 100px)
    }
  }
}
.file-upload {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 170px;

  &-input {
    position: absolute;
    left: -99999px;
  }
  &-block {
    width: 66px;
    height: 66px;
    border-radius: 13px;
    background-color: rgba(116, 131, 169, .13);
    display: inline-block;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  &-link {
    display: inline-block;
    margin-left: 20px;

    color: #2D4379;
    font-family: "Graphik LCG";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;

    position: absolute;
    top: 23px;
    left: 66px;
  }
}

.profile-avatar-link-block {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
}
</style>

