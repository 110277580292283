<template>
  <div v-if="campaign">
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$router.push({name: 'Campaign', params: {id: $route.params.id}})" class="vm--close">
    </div>

    <div class="share-form" v-scroll-lock="true">
      <h1 class="share-form-title color-dark-blue">{{ $t('share-modal.title') }}</h1>
      <h4 class="share-form-description color-text-desc">{{ $t('share-modal.description') }}</h4>

      <div class="share-form-content">
        <div class="form-group">
          <label for="page-current-url" class="control-label">{{ $t('share-modal.Link') }}</label>
          <div class="copy-link-block">
            <input type="text" class="form-control" id="page-current-url" :value="currentUrl"/>
            <button class="btn btn-primary" @click="copy">{{ $t('share-modal.Copy link') }}</button>
          </div>
        </div>

        <div class="socials-block-wrap">
          <div class="socials-block-title">{{ $t('share-modal.messengers-text') }}</div>
          <ul class="socials-block">
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="telegram" class="telegram"></ShareNetwork>
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="viber" class="viber"></ShareNetwork>
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="whatsapp" class="whatsapp"></ShareNetwork>
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="skype" class="skype"></ShareNetwork>
          </ul>
        </div>

        <div class="socials-block-wrap">
          <div class="socials-block-title">{{ $t('share-modal.socials-text') }}</div>
          <ul class="socials-block">
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="facebook" class="facebook"></ShareNetwork>
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="twitter" class="twitter"></ShareNetwork>
<!--            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="vk" class="vk"></ShareNetwork>-->
            <ShareNetwork tag="li" :url="currentUrl" :description="campaign.short_description" :media="campaignImage" :title="campaign.title" network="odnoklassniki" class="ok"></ShareNetwork>
          </ul>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Share',
  computed: {
    currentUrl () {
      return location.origin + this.$router.resolve({
        name: 'Campaign',
        params: {
          id: this.$route.params.id
        }
      }).href
    },
    campaignImage () {
      let str = this.campaign.images[0]

      if (str) {
        str = str.replace('{w}', '')
        str = str.replace('{h}', '')
      }

      return str;
    }
  },
  asyncComputed: {
    campaign () {
      return this.$sdk.campaign.view(this.$route.params.id, {
        expand: 'author,category'
      }).then(response => response.data)
    }
  },
  methods: {
    copy () {
      this.$copyText(this.currentUrl)
      let mess = this.$t('share-modal.copied')
      this.$toasted.info(mess)
    }
  }
}
</script>

<style lang="less" scoped>

.share-form {
  &-title {
    margin-bottom: 15px;
  }

  &-description {
    margin-bottom: 35px;
    font-size: 12px;
  }

  &-content {
    display: grid;
  }
}

.copy-link-block {
  display: flex;
  grid-column-gap: 18px;

  .form-control {
    width: 350px;
  }
  button {
    white-space: nowrap;
  }

  @media (max-width:1000px) {
    display: block;
    grid-column-gap: unset;

    .form-control {
      width: 100%;
    }

    button {
      width: 100%;
      display: block;
      margin-top: 20px;
    }
  }
}

.socials-block-wrap {
  margin-top: 30px;

  .socials-block-title {
    color: #7483A9;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.29px;
    line-height: 14px;
    margin-bottom: 10px;
  }
}

</style>
