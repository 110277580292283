<template>
  <div class="block block-slider" v-if="campaigns && campaigns.length > 0">

    <div class="list-data">
      <transition-group name="slide-images">
        <div class="list-data-item" :class="{active: index === activeSlide}" v-for="(campaign, index) in campaigns" :key="campaign.id">

          <h1 class="list-data-title color-dark-blue">{{ campaign.title }}</h1>

          <p class="list-data-text color-text-desc">
            {{ campaign.short_description }}
          </p>

          <router-link :to="{name: 'Campaign', params: {id: campaign.id}}" tag="button" class="btn btn-primary">{{ $t('slider.view') }}</router-link>

          <ul class="list-data-points" v-if="campaigns.length">
            <li
                @click.prevent="changeSlide(index)"
                v-for="(campaign, index) in campaigns" v-bind:key="index"
                :class="{active: index === activeSlide}"
            ></li>
          </ul>
        </div>
      </transition-group>
    </div>

    <div class="list-images" ref="box">
      <div class="list-images-wrap">
        <div class="list-images-line" :style="listStyle">
          <img :src="getImageUrl(campaign.images[0], 600, 434)"
               :srcset="getImageUrl(campaign.images[0], 1200, 868) + ' 2x'"
               :class="{active: index === activeSlide}" :ref="'images[' + index + ']'"
               @click="$router.push({name: 'Campaign', params: {id: campaign.id}})"
               v-for="(campaign, index) in campaigns" v-bind:key="index">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'slider',
  data () {
    return {
      activeSlide: 0,
      listStyle: {
        left: 0
      },
      campaigns: []
    }
  },
  asyncComputed: {
    campaigns () {
      return this.$sdk.campaign.find({
        params: {
          show_on_slider: 1
        }
      }).then(response => response.data)
    }
  },
  methods: {
    getImageUrl (url, width, height) {
      let str = url.replace('{w}', width)
      str = str.replace('{h}', height)

      return str
    },
    changeSlide (index) {
      this.activeSlide = index;

      const width = this.$refs['images[' + index + ']'][0].offsetLeft;
      this.listStyle.left = -width + 'px';
    },
  }
}
</script>
