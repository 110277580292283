<template>
  <div>
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="close" class="vm--close">
    </div>

    <form class="form-pay" @submit.prevent="submit">
      <h1 class="color-dark-blue">{{ $t('pay-form.Donate campaign') }}</h1>
      <h4 class="color-text-desc">{{ $t('pay-form.Donate money as you see fit') }}</h4>

      <form-field :label="$t('pay-form.amount-label')" :error="errors.amount" :value="form.amount">
        <input type="text" v-model="form.amount" @keyup="validate(['amount'])" class="form-control"/>
      </form-field>

      <form-field :label="$t('pay-form.name-label')" :error="errors.user_name" :value="form.user_name">
        <input type="text" v-model="form.user_name" @keyup="validate(['user_name'])" class="form-control" :placeholder="$t('pay-form.name-placeholder')"/>
      </form-field>

      <form-field :label="$t('pay-form.email-label')" :error="errors.email" :value="form.email">
        <input type="text" v-model="form.email" @keyup="validate(['email'])" class="form-control" :placeholder="$t('pay-form.email-placeholder')"/>
      </form-field>

      <button type="submit" class="btn btn-primary btn-block">{{ $t('pay-form.donate-button') }}</button>
    </form>
  </div>
</template>

<script>
import FormField from "@/components/FormField";
import '@stripe/stripe-js'

export default {
  name: 'CampaignPay',
  components: {
    FormField
  },
  data () {
    return {
      form: {
        amount: 100,
        email: '',
        user_name: '',
      },
      errors: {},

      stripe_items: [],
      stripe_public_key: '',
      stripe_session_id: ''
    }
  },
  computed: {
    campaignId () {
      return this.$route.params.id
    },
    formData () {
      return {
        amount: this.form.amount,
        email: this.form.email,
        user_name: this.form.user_name,
        success_url: this.resolveUrl({
          name: 'CampaignPaySuccess', params: {
            id: this.campaignId
          }
        }),
        cancel_url: this.resolveUrl({
          name: 'CampaignPayError', params: {
            id: this.campaignId
          }
        })
      }
    }
  },
  methods: {
    submit () {
      this.$sdk.campaign.pay(this.campaignId, this.formData, false).then(response => {
        if (response.data.checkout_session_id !== undefined) {
          const stripe = window.Stripe(response.data.stripe_public_key, {
            stripeAccount: response.data.connected_stripe_account_id
          })
          stripe.redirectToCheckout({
            sessionId: response.data.checkout_session_id
          })
        } else {
          this.errors = response.data
        }
      })
    },
    validate (attributes = []) {
      this.$sdk.campaign.pay(this.campaignId, this.formData, true).then(response => {
        for (let attribute of attributes) {
          if (response.data[attribute] !== undefined) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    },
    close () {
      this.$router.push({
        name: 'Campaign',
        params: {
          id: this.campaignId
        }
      })
    },
    resolveUrl (route) {
      return window.location.origin + this.$router.resolve(route).href
    }
  }
}
</script>

<style lang="less">
.form-pay {
  max-width: 640px;
  width: 100%;

  h1 {
    margin-bottom: 15px;
  }

  h4 {
    color: #7483A9;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 30px;
  }
}
</style>
