<template>
  <div>
    <campaign-comment-form v-if="!this.replyTo && isUser" :campaign-id="campaign.id" @success="updateComments" :label="$t('campaign-comment.description-label')" :placeholder="$t('campaign-comment.description-placeholder')"/>
    <campaign-comment-item v-for="comment in this.comments" v-bind:key="comment.id"
     :comment="comment"
     v-slot:default="params"
     @reply="toogleReplyForm(comment.id)"
    >
      <div class="comment-children">
        <campaign-comment-item v-for="child in params.children" v-bind:key="child.id" :comment="child"
          @reply="toogleReplyForm(comment.id)"
        />
        <campaign-comment-form v-if="replyTo === comment.id" :campaign-id="campaign.id"
          @success="updateComments"
          @reset="reset"
          :reply-id="comment.id"
        />
      </div>
    </campaign-comment-item>
    <no-results v-if="this.comments && this.comments.length === 0" :text="$t('campaign-comment.no comments found')"></no-results>

<!--    <button class="btn btn-primary btn-more">Show more</button>-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CampaignCommentItem from "@/views/campaign/comments/CampaignCommentItem";
import CampaignCommentForm from "@/views/campaign/comments/CampaignCommentForm";
import NoResults from "@/views/blocks/NoResults";

export default {
  name: 'CampaignComment',
  props: ['campaign'],
  components: {
    CampaignCommentItem,
    CampaignCommentForm,
    NoResults
  },
  data() {
    return {
      replyTo: false
    }
  },
  computed: {
    ...mapGetters(['isUser'])
  },
  asyncComputed: {
    comments: {
      get () {
        return this.$sdk.comment.find(this.campaign.id, {
          expand: ['author', 'children.author'].join(',')
        }).then(response => {
          this.$emit('update-comments-count', response.headers['x-pagination-total-count'])
          return response.data
        })
      },
      default: []
    }
  },
  methods: {
    updateComments () {
      this.$asyncComputed.comments.update()
    },
    reset () {
      this.replyTo = false
    },
    toogleReplyForm (comment_id) {
      this.replyTo = this.replyTo ? false : comment_id
    }
  }
}
</script>
