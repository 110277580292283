<template>
  <div class="form-group" :class="{'has-errors': hasError, 'has-success': hasSuccess}">
    <label class="control-label">{{ label }}</label>
    <div class="help-block">{{ errorMessage }}</div>
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    value: {},
    label: {
      type: String,
    },
    error: {
      type: [String, Array],
    },
    help: {
      type: String,
    }
  },
  computed: {
    /**
     * Set class has-errors if field is not empty or false
     *
     * @return {""|boolean}
     */
    hasError () {
      return !!this.errorMessage
    },
    hasSuccess () {
      return !!(this.value && !this.hasError)
    },

    /**
     * Get first error from array
     * @return string
     */
    errorMessage () {
      if (Array.isArray(this.error)) {
        return this.error.length > 0 ? this.error[0] : ''
      } else {
        return this.error || ''
      }
    },
  }
}
</script>
