<template>
  <form @submit.prevent="submit">
    <form-field :error="fieldErrors" :label="label" :value="form.text">
      <textarea class="form-control comment-textarea" @keyup="validate(['text', 'campaign_id'])" :placeholder="placeholder" v-model="form.text"/>
    </form-field>

    <transition name="fade">
      <div class="text-right" v-if="isButtonsVisible">
        <button type="button" class="btn btn-inline btn-white btn-quarter" style="margin-right: 10px" @click="cancel">
          <icon-close color="#FF4C54" />
        </button>
        <button type="submit" class="btn btn-inline btn-white btn-quarter">
          <icon-checkbox color="#23AE8D" />
        </button>
      </div>
    </transition>
  </form>
</template>

<script>
import FormField from '@/components/FormField';
import IconClose from "@/assets/icons/IconClose";
import IconCheckbox from "@/assets/icons/IconCheckbox";

export default {
  name: 'CampaignCommentForm',
  components: {
    FormField,
    IconClose,
    IconCheckbox,
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default () {
        return this.$t('campaign-comment-form.comment-field-placeholder')
      }
    },
    campaignId: {
      type: Number,
      required: true
    },
    commentId: {
      type: Number,
      default: 0
    },
    replyId: {
      type: [Number],
      default: 0
    }
  },
  data () {
    return {
      form: {
        text: ''
      },
      errors: {}
    }
  },
  asyncComputed: {
    comment () {
      if (!this.isNewRecord) {
        return this.$sdk.comment
          .view(this.commentId)
          .then(response => {
            this.form.text = response.data.text

            return response.data
          })
      }
    }
  },
  created () {
    if (this.replyId) {
      this.form.parent_id = this.replyId
    }
  },
  computed: {
    isButtonsVisible () {
      return this.form.text.length > 0
    },
    isNewRecord () {
      return this.commentId === 0
    },
    fieldErrors () {
      if ('text' in this.errors) {
        return this.errors['text'][0]
      }

      if ('campaign_id' in this.errors) {
        return this.errors['campaign_id'][0]
      }

      return ''
    }
  },
  methods: {
    submit () {
      let promise = null

      if (this.isNewRecord) {
        promise = this.$sdk.comment.create(this.campaignId, this.form, false)
      } else {
        promise = this.$sdk.comment.update(this.commentId, this.form, false)
      }

      promise.then(response => {
        if (Array.isArray(response.data)) {

          let mess = ''
          if (this.isNewRecord) {
            mess = this.$t('campaign-comment-form.comment-created')
          } else {
            mess = this.$t('campaign-comment-form.comment-updated')
          }
          this.$toasted.info(mess)

          this.$emit('success')
          this.cancel()
        } else {
          this.errors = response.data
        }
      })
    },
    cancel () {
      this.form.text = ''
      this.errors = {}

      this.$emit('reset')
    },

    validate (attrs = []) {
      let promise = null

      if (this.isNewRecord) {
        promise = this.$sdk.comment.create(this.campaignId, this.form, true)
      } else {
        promise = this.$sdk.comment.update(this.commentId, this.form, true)
      }

      promise.then(response => {
        for (let attribute of attrs) {
          if (response.data[attribute]) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .comment-textarea {
    height: 65px;
    transition: height .3s ease;

    &:focus {
      height: 180px;
    }
  }
</style>
