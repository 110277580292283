<template>
  <div>
    <campaign-news-form v-show="isAuthor" :id="campaign.id" @update-news="update"/>

    <div v-for="item in this.news" v-bind:key="item.id" style="margin-top: 60px;">
      <campaign-news-form v-if="editId === item.id" :id="campaign.id" :news-id="item.id" @reset="editId = false" @update-news="updateArticle()" />
      <div class="news">
        <div v-if="isAuthor" @click="editId = item.id" class="news-edit"><icon-edit color="#7483A9"/></div>
        <div class="news-date">{{ item.created_at | moment("DD MMMM YYYY, HH:ss") }}</div>
        <h4 class="news-title">{{ item.title }}</h4>
        <p class="news-text" ref="news" :class="{full: isOpened(item.id)}">{{ item.description }}</p>
        <div class="news-more" v-if="item.description.length > 200" @click="showMore(item.id)">
          <span v-if="!isOpened(item.id)">{{ $t('news.More') }}</span>
          <span v-else>{{ $t('news.Hide') }}</span>
        </div>
      </div>
    </div>
    <no-results v-if="this.news && this.news.length === 0" :text="$t('news.No news found')"></no-results>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import CampaignNewsForm from '@/views/campaign/news/CampaignNewsForm';
import NoResults from "@/views/blocks/NoResults";
import IconEdit from "@/assets/icons/IconEdit";

export default {
  name: 'CampaignNews',
  components: {
    CampaignNewsForm,
    NoResults,
    IconEdit
  },
  props: ['campaign'],
  data () {
    return {
      editId: false,
      openedItems: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isAuthor() {
      return this.user && this.campaign && this.campaign.author_id === this.user.id
    }
  },
  asyncComputed: {
    news () {
      return this.$sdk.news
        .find(this.campaign.id)
        .then(response => {
          this.$emit('update-news-count', response.headers['x-pagination-total-count'])
          return response.data
        })
    }
  },
  methods: {
    update () {
      this.$asyncComputed.news.update()
    },
    updateArticle () {
      this.$asyncComputed.news.update()
      this.editId = false
    },
    isOpened(id) {
      return this.openedItems.includes(id)
    },
    showMore(id) {
      let index = this.openedItems.indexOf(id)
      if (index > -1) {
        this.openedItems.splice(index, 1)
      } else {
        this.openedItems.push(id)
      }
    }
  }
}
</script>
<style lang="less">
.news {
  position: relative;

  &-text {
    &.full {
      height: auto;
      max-height: initial;
    }
  }

  &-more {
    cursor: pointer;
  }

  &-edit {
    display: none;
    cursor: pointer;

    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    .news-edit {
      display: block;
    }
  }
}

.news-form + .news {
  display: none;
}
</style>
