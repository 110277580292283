<template>
    <form @submit.prevent="submit" class="campaign-create">

        <form-field :value="form.title" :error="errors.title" :label="$t('campaign-form.title-label')">
            <input type="text" @keyup="validate('title')" v-model="form.title" :placeholder="$t('campaign-form.title-placeholder')" class="form-control">
        </form-field>

        <form-field :value="form.category_id" :error="errors.category_id" :label="$t('campaign-form.category-label')">
            <v-select label="name" :options="categories || []" v-model="form.category_id"
                      class="form-control"
                      :placeholder="$t('campaign-form.category-placeholder')"
                      @input="validate(['category_id'])"></v-select>
        </form-field>

        <form-field :value="form.description" :error="errors.short_description" :label="$t('campaign-form.description-label')">
            <textarea v-model="form.description" @keyup="validate('short_description')"
                      :placeholder="$t('campaign-form.description-placeholder')"
                      class="form-control"></textarea>
        </form-field>

        <form-field :value="form.required_amount" :error="errors.required_amount" :label="$t('campaign-form.amount-label')">
            <input type="text" class="form-control" @keyup="validate('required_amount')" v-model="form.required_amount">
        </form-field>

        <form-field :value="form.file" :error="errors.images" :label="$t('campaign-form.cover-label')">
            <div class="input-upload-wrapper form-control"
                 :class="{'uploaded': isFileUploaded, 'loading': isFileLoading, 'dragable': isFileDragging}"
                 @drop.prevent="fileUpload"
                 @dragover.prevent
                 @dragenter.prevent="isFileDragging = true"
                 @dragleave.prevent="isFileDragging = false"
            >

                <img src="/images/icons_svg/insert_photo.svg" class="input-upload-photo" alt="">

                <label for="image-upload" class="input-upload-btn">
                    <input type="file" id="image-upload" @change="fileUpload">
                    <span class="color-dark-blue">
                        <icon-camera color="#7483A9"/>
                        <span v-if="!isFileUploaded">{{ $t('campaign-form.upload-cover') }}</span>
                        <span v-else>{{ $t('campaign-form.replace-cover') }}</span>
                    </span>
                </label>

                <span class="input-upload-txt">{{ $t('campaign-form.image-info') }}</span>
                <img class="input-upload-preview" v-if="isFileUploaded" :src="getUploadedImageUrl(640, 436)" alt="">
            </div>
        </form-field>

        <form-field :value="form.text" :error="errors.description" :label="$t('campaign-form.full-description-label')">
            <textarea @keyup="validate('description')" v-model="form.text" :placeholder="$t('campaign-form.full-description-placeholder')" class="form-control form-control-textarea"></textarea>
        </form-field>

        <div class="form-buttons">
          <button type="submit" class="btn btn-primary btn-block btn-lg" v-if="isNewRecord">{{ $t('campaign-form.open-campaign-button') }}</button>
          <button type="submit" class="btn btn-primary btn-block btn-lg" v-else>{{ $t('campaign-form.update-campaign-button') }}</button>
          <button type="button" class="btn btn-danger btn-block btn-lg min" v-if="canDelete" @click.prevent="deleteCampaign">{{ $t('campaign-form.delete-campaign-button') }}</button>
        </div>
    </form>
</template>

<script>
  import IconCamera from '@/assets/icons/IconCamera';
  import FormField from '@/components/FormField';
  import { mapState } from 'vuex'

  export default {
    name: 'CampaignForm',
    components: {
      IconCamera,
      FormField
    },
    data() {
      return {
        form: {
          title: '',
          description: '',
          text: '',
          file: null,
          category_id: null,
          required_amount: 10000
        },
        isFileLoading: false,
        isFileDragging: false,
        errors: {}
      }
    },
    asyncComputed: {
      categories () {
        return this.$sdk.category.find().then(response => response.data)
      },
      campaign () {
        if (!this.isNewRecord) {
          return this.$sdk.campaign.view(this.id, {
            expand: ['author', 'category'].join(',')
          }).then(response => {
            this.loadExistsCampaign(response.data)
            return response.data
          })
        }
      }
    },
    computed: {
      ...mapState({
        user: state => state.user.user
      }),
      canDelete () {
        return this.campaign &&
            this.campaign.permission &&
            this.campaign.permission.canDelete
      },
      isNewRecord () {
        return !this.id
      },
      id () {
        return this.$route.params.id
      },
      isFileUploaded () {
        return this.form.file !== null &&
          Object.keys(this.form.file).length > 0
      },
      formPost () {
        return {
          title: this.form.title,
          description: this.form.text,
          short_description: this.form.description,
          category_id: this.form.category_id ? this.form.category_id.id : '',
          required_amount: this.form.required_amount,
          images: this.isFileUploaded ? this.form.file.name: []
        }
      }
    },
    methods: {
      loadExistsCampaign(campaign) {
        this.form.title = campaign.title
        this.form.description = campaign.short_description
        this.form.text = campaign.description
        this.form.required_amount = campaign.required_amount
        this.form.file = campaign.images[0] ? {
          name: this.basename(campaign.images[0]),
          url: campaign.images[0]
        } : null
        this.form.category_id = campaign.category
      },

      basename(path) {
        return path.split('/').reverse()[0];
      },

      fileUpload(event) {
        this.isFileDragging = false
        let file = undefined

        if (event.dataTransfer && event.dataTransfer.files) {
          file = event.dataTransfer.files[0];
        } else {
          file = event.target.files[0];
        }

        let form = new FormData();
        form.append('file', file);

        this.form.file = null;
        this.isFileLoading = true;

        this.$sdk.campaign.image(form).then(response => {
          if (response.data.error !== undefined) {
            this.errors.images = response.data.error
          } else {
            this.form.file = response.data
          }
        }).finally(() => {
          this.isFileLoading = false
          this.validate('images')
        })
      },
      getUploadedImageUrl(width, height) {
        if (this.isFileUploaded && this.form.file) {
          let url = this.form.file.url;

          url = url.replace('{w}', width);
          url = url.replace('{h}', height);

          return url
        }

        return ''
      },

      /**
       *
       * @param {string|array} attributes
       */
      validate (attributes) {
        if (typeof attributes === 'string') {
          attributes = [attributes]
        } else if (!Array.isArray(attributes)) {
          console.error('Attributes must be string or array')
        }

        const form = this.formPost
        const promise = this.isNewRecord
          ? this.$sdk.campaign.create(form)
          : this.$sdk.campaign.update(this.id, form)

        promise.then((response) => {
          for (const attribute of attributes) {
            if (response.data[attribute] === undefined) {
              this.$delete(this.errors, attribute)
            } else {
              this.$set(this.errors, attribute, response.data[attribute][0])
            }
          }
        })
      },

      submit() {
        let form = this.formPost;
        let promise = this.isNewRecord
          ? this.$sdk.campaign.create(form, false)
          : this.$sdk.campaign.update(this.id, form, false)

        promise.then(response => {
          if (Array.isArray(response.data) && response.data.length === 0) {

            let mess = ''
            if (this.isNewRecord) {
              mess = this.$t('campaign-form.campaign-has-been-created')
            } else {
              mess = this.$t('campaign-form.campaign-has-been-updated')
            }

            this.$toasted.info(mess)

            const id = this.isNewRecord
                ? response.headers['x-id']
                : this.campaign.id;

            this.$router.push({name: 'MyCampaignShow', params: {id: id}})
          } else {
            this.errors = response.data
          }
        })
      },

      getErrorMessage(attribute) {
        if (this.errors[attribute] !== undefined) {
          if (Array.isArray(this.errors[attribute])) {
            return this.errors[attribute][0]
          } else {
            return this.errors[attribute]
          }
        }

        return null
      },

      deleteCampaign () {
        let mess = this.$t('campaign-form.delete-confirm-text')

        this.$toasted.info('<div>' + mess + '</div>', {
          theme: 'dialog',
          duration: false,
          action: [
            {
              text: this.$t('campaign-form.cancel-confirm-button'),
              class: 'cancel',
              onClick : (e, toastObject) => {
                toastObject.goAway(0);
              }
            },
            {
              text: this.$t('campaign-form.continue-confirm-button'),
              class: 'continue',
              onClick : (e, toastObject) => {
                toastObject.goAway(0);

                this.$sdk.campaign.delete(this.campaign.id).then(() => {
                  this.$router.push({name: 'AccountCampaigns'})
                  this.$toasted.info(this.$t('campaign-form.campaign-has-been-deleted'))
                })
              }
            }
          ]
        })
      }
    }
  }
</script>


<style lang="less">
  .campaign-create {
    max-width: 640px;
    margin: 0 auto;
    padding-bottom: 60px;

    &-title {
      margin-bottom: 60px;
    }

    textarea {
      min-width: 100%;
      max-width: 100%;
    }

    .input-upload-wrapper {
      box-sizing: border-box;
      min-height: 436px;
      width: 100%;
      border: 1px solid rgba(45,67,121,0.13);
      border-radius: 66px;
      position: relative;
      padding: 60px 50px;
      overflow: hidden;
      background-color: #F8FAFF;

      transition: all .3s ease;

      .input-upload-photo {
        display: block;
        width: 120px;
        height: 120px;

        position: absolute;
        top: 85px;
        left: 50%;

        margin-left: -60px;
        opacity: .2;
        pointer-events: none;
      }

      .input-upload-txt {
        display: block;
        text-align: center;
        //width: 100%;
        color: #7483A9;

        position: absolute;
        bottom: 60px;
        left: 0; right: 0;

        padding-left: 40px;
        padding-right: 40px;

        width: calc(100% - 80px);
        line-height: 1.65em;
      }

      .input-upload-btn {
        display: block;
        box-sizing: border-box;
        border: 1px solid rgba(45, 67, 121, 0.13);
        border-radius: 13px;
        background-color: #FFFFFF;
        box-shadow: 0 11px 13px 0 rgba(45, 67, 121, 0.06);
        padding: 18px 41px;

        overflow: hidden;
        position: absolute;
        top: 235px;
        left: 50%;
        transform: translate(-50%);
        width: min-content;
        white-space: nowrap;
        z-index: 4;

        transition: all .3s ease;

        cursor: pointer;

        input {
          position: absolute;
          left: -9999px;
          visibility: hidden;
        }

        svg {
          margin-right: 10px;
          position: relative;
          top: -2px;
        }

        span > svg,
        span > span {
          vertical-align: middle;
        }

        &:focus,
        &:hover {
          box-shadow: 0 11px 13px 0 rgba(45,67,121,0.13);
        }
      }

      .input-upload-preview {
        position: absolute;
        left: 0; top: 0;
        border-radius: 13px;
        image-rendering: optimizespeed;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 3;
      }

      &.uploaded {
        &::before {
          display: block;
          content: ' ';

          left: 0; top: 0;
          width: 100%;
          height: 100%;

          background: #F8FAFF;

          z-index: 3;
          position: absolute;
        }

        .input-upload-btn {
          position: absolute;
          left: 50%; top: 50%;
          transform: translate(-50%, -50%);
          border-color: transparent;

          z-index: 4;
        }
      }
      &.loading {
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJiYWNrZ3JvdW5kOiByZ2IoMjQxLCAyNDIsIDI0MykiIHdpZHRoPSI0MyIgaGVpZ2h0PSI0MyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiIHZpZXdCb3g9IjAgMCA0MyA0MyI+PGcgdHJhbnNmb3JtPSJzY2FsZSgwLjE3KSI+PGRlZnM+PGcgaWQ9InN0cmlwZSI+PHBhdGggZD0iTTI1NiAtMTI4IEwzODQgLTEyOCBMLTEyOCAzODQgTC0xMjggMjU2IFoiIGZpbGw9IiNmZmZmZmYiPjwvcGF0aD48cGF0aCBkPSJNMzg0IDAgTDM4NCAxMjggTDEyOCAzODQgTDAgMzg0IFoiIGZpbGw9IiNmZmZmZmYiPjwvcGF0aD48L2c+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE5Ni4yMDggMCkiPjx1c2UgaHJlZj0iI3N0cmlwZSIgeD0iLTI1NiIgeT0iMCI+PC91c2U+PHVzZSBocmVmPSIjc3RyaXBlIiB4PSIwIiB5PSIwIj48L3VzZT48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIga2V5VGltZXM9IjA7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMC41cyIgdmFsdWVzPSIwIDA7IDI1NiAwIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9nPjwvZz48L3N2Zz4K");
      }

      &.uploaded,
      &.loading {
        .input-upload-photo {
          display: none;
        }

        .input-upload-txt {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      &.dragable {
        border-style: dashed;

        .input-upload-preview,
        .input-upload-btn,
        .input-upload-txt {
          display: none;
        }

        .input-upload-photo {
          top: 150px;
          display: block;
        }

        &:after {
          content: 'File in drop zone';
          position: absolute;
          top: 270px;
          text-align: center;
          display: block;
          pointer-events: none;
          left: 0;
          width: 100%;
          transition: all .3s ease;
        }
      }
    }

    @media (max-width: 1000px) {
      .input-upload-wrapper {
        min-height: 280px;
        border-radius: 18px;

        .input-upload-photo {
          top: 20px
        }

        .input-upload-btn {
          top: 141px;
        }

        .input-upload-txt {
          bottom: 20px;
          font-size: 12px;
        }

        &.dragable {
          .input-upload-photo {
            top: 60px;
          }
          &:after {
            top: 200px;
          }
        }
      }
    }

    .form-buttons {
      display: flex;
      grid-gap: 20px;

      .min {
        width: min-content;
      }
    }

    .form-control-textarea {
      transition: all 1s ease;
      height: auto;

      &:focus {
        height: 300px;
      }
    }
  }
</style>
