import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import Home from '@/views/Home.vue'
import CampaignCreate from '@/views/CampaignCreate';
import Campaign from '@/views/Campaign'
import Account from '@/views/Account';
import AccountCampaigns from '@/views/AccountCampaigns';
import AccountProfile from '@/views/AccountProfile';
import AccountIntegrations from '@/views/AccountIntegrations';
import Page from '@/views/Page'

import CampaignShow from "@/views/campaign/CampaignShow";
import CampaignTabs from "@/views/campaign/CampaignTabs";
import QuestionForm from "@/views/campaign/questions/QuestionForm";
import CampaignComment from "@/views/campaign/comments/CampaignComment";
import CampaignNews from "@/views/campaign/news/CampaignNews";
import CampaignForm from "@/views/campaign/CampaignForm";

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang(en|ru|by)',
    component: {
      render: h => h('router-view'),
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        children: [
          {
            path: 'filter/:slug/:status(all|popular|finished|new)?',
            name: 'HomeFilter'
          },
          {
            path: 'login',
            name: 'Login',
            meta: {
              modal: 'login',
            }
          },
          {
            path: 'registration',
            name: 'Registration',
            meta: {
              modal: 'registration',
            }
          },
          {
            path: 'registration-success',
            name: 'RegistrationSuccess',
            meta: {
              modal: 'registration-success'
            }
          },
          {
            path: 'auth/verify-email/:token',
            name: 'VerifyEmail',
          },
          {
            path: 'auth/reset-password/:token',
            name: 'SetPassword',
            meta: {
              modal: 'set-password'
            }
          },
          {
            path: 'reset-password',
            name: 'ResetPassword',
            meta: {
              modal: 'reset-password'
            },
          },
          {
            path: 'filter',
            name: 'CampaignFilter',
            meta: {
              modal: 'campaign-filter'
            }
          }
        ]
      },
      {
        path: 'auth/social',
        name: 'SocialAuth',
        component: Home
      },
      {
        path: 'page/:slug',
        name: 'Page',
        component: Page
      },
      {
        path: 'campaign/create',
        name: 'CampaignCreate',
        component: CampaignCreate,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'campaign/:id',
        name: 'Campaign',
        component: Campaign,
        children: [
          {
            path: 'pay',
            name: 'CampaignPay',
            meta: {
              modal: 'campaign-pay'
            }
          },
          {
            path: 'pay-success',
            name: 'CampaignPaySuccess',
            meta: {
              noty: 'Thank you for your payment!'
            }
          },
          {
            path: 'pay-error',
            name: 'CampaignPayError',
            meta: {
              noty: 'Something was wrong. Please try again later'
            }
          },
          {
            path: 'share',
            name: 'CampaignShare',
            meta: {
              modal: 'share'
            }
          },
          {
            path: 'payments',
            name: 'CampaignPayments',
            meta: {
              modal: 'payments'
            }
          },
          {
            path: 'questions',
            name: 'CampaignQuestions',
            component: QuestionForm,
          },
          {
            path: 'news',
            name: 'CampaignNews'
          },
          {
            path: 'comments',
            name: 'CampaignComments'
          }
        ]
      },
      {
        path: 'account',
        component: Account,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'campaigns',
            name: 'AccountCampaigns',
            component: AccountCampaigns
          },
          {
            path: 'profile',
            name: 'AccountProfile',
            component: AccountProfile
          },
          {
            path: 'integrations',
            name: 'AccountIntegrations',
            component: AccountIntegrations
          },
          {
            path: '',
            redirect: {
              name: 'AccountCampaigns'
            }
          }
        ]
      },
      {
        path: 'account/campaigns/:id',
        name: 'CampaignTabs',
        component: CampaignTabs,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'about',
            name: 'MyCampaignShow',
            component: CampaignShow,
            children: [
              {
                path: 'stripe',
                name: 'CampaignAboutWithStripeModal',
                meta: {
                  modal: 'stripe-modal'
                }
              }
            ]
          },
          {
            path: 'edit',
            name: 'MyCampaignEdit',
            component: CampaignForm,
          },
          {
            path: 'comments',
            name: 'MyCampaignComments',
            component: CampaignComment
          },
          {
            path: 'news',
            name: 'MyCampaignNews',
            component: CampaignNews
          }
        ]
      },
      {
        path: '*',
        redirect: '/en'
      }
    ]
  },
  {
    path: '*',
    redirect: url => {
      return '/en' + url.path
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,

  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    if (['Campaign', 'Home'].includes(to.name)) {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу логина
    if (!store.getters.isUser) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next() // всегда так или иначе нужно вызвать next()!
  }
})

export default router
