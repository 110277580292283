<template>
  <div>
    <div class="campaigns-grid-list" v-if="campaigns">
      <router-link tag="div" :to="{name: 'MyCampaignShow', params: {id: campaign.id}}" class="campaign campaign-scalable campaign-account" v-for="campaign in campaigns" v-bind:key="campaign.id">
        <div class="campaign-category">
          <span :class="getCampaignMarkerColor(campaign)">{{ getCampaignMarkerLabel(campaign) }}</span>
        </div>
        <img :src="getImageUrl(campaign.images[0], 600, 434)" class="campaign-image" :alt="campaign.title">
        <div class="campaign-data">
          <h3 class="campaign-title">{{ campaign.title }}</h3>
          <p class="campaign-description">{{ campaign.short_description }}</p>

          <div class="campaign-numbers">
            <div class="campaign-numbers-left"><b>{{ campaign.collected_amount | numeral }}</b> EUR</div>
            <div class="campaign-numbers-right" v-html="$t('campaign.Raised of <b>{count}</b> {currency}', {count: $options.filters.numeral(campaign.required_amount), currency: 'EUR'})"></div>
          </div>
          <div class="campaign-timeline">
            <div class="campaign-timeline-bar" :style="{width: ((100 * campaign.collected_amount) / campaign.required_amount)  + '%'}"></div>
          </div>
        </div>
        <button class="btn btn-primary btn-block">{{ $t('campaign.view-button-text') }}</button>
      </router-link>
    </div>
    <no-result v-if="!campaigns.length"></no-result>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NoResults from "@/views/blocks/NoResults";
import CampaignMixin from "@/components/mixins/CampaignMixin";

export default {
  name: 'AccountCampaigns',
  mixins: [CampaignMixin],
  components: {
    'no-result': NoResults
  },
  computed: {
    ...mapState({
      'user': state => state.user.user
    })
  },
  asyncComputed: {
    campaigns: {
      get () {
        if (this.user) {
          const params = {
            author_id: this.user.id,
            is_public: 0,
            expand: ['category'].join(',')
          }
          return this.$sdk.campaign.find({
            params: params
          }).then(response => response.data)
        }
      },
      default: []
    }
  },
  methods: {
    getImageUrl (url, width, height) {
      url = url.replace('{w}', width)
      url = url.replace('{h}', height)

      return url
    }
  }
}
</script>
<style lang="less" scoped>
.campaign {
  cursor: pointer;
}
.campaign + .campaign {
  margin-bottom: 60px;
}
</style>
