<template>
  <div>
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$emit('close')" class="vm--close">
    </div>

    <div class="registration-form" v-scroll-lock="true">
      <h1 class="registration-form-title color-dark-blue">{{ $t('login-modal.title') }}</h1>
      <h4 class="registration-form-description color-text-desc">{{ $t('login-modal.Do not registered?') }} <router-link :to="{name: 'Registration'}">{{ $t('login-modal.after-title-sign-up-text') }}</router-link> {{ $t('login-modal.after-title-or') }} <router-link :to="{name: 'ResetPassword'}">{{ $t('login-modal.after-title-reset-text') }}</router-link></h4>

      <form class="registration-form-content" @submit.prevent="submit()">
        <div class="left-block">

          <form-field :label="$t('login-modal.email-label')" :value="form.email">
            <input type="text" v-model="form.email" class="form-control" :placeholder="$t('login-modal.email-placeholder')"/>
          </form-field>

          <form-field :label="$t('login-modal.password-label')" :value="form.password">
            <input type="password" v-model="form.password" class="form-control" :placeholder="$t('login-modal.password-placeholder')"/>
          </form-field>

          <transition name="fade">
            <div class="registration-form-error" v-show="this.error">{{ this.error }}</div>
          </transition>

          <button type="submit" class="btn btn-primary" :disabled="!form.email || !form.password">{{ $t('login-modal.sign-in-button-text') }}</button>
        </div>
        <div class="right-block">
          <login-with />
        </div>
      </form>

    </div>

    <div class="other hidden-xs">
      {{ $t('login-modal.Any questions?') }} <a href="mailto:info@getdonate.org">{{ $t('login-modal.Contact right now') }}</a>
    </div>

  </div>
</template>

<script>
import FormField from '@/components/FormField';
import LoginWith from "@/views/blocks/LoginWith";

export default {
  name: 'Login',
  components: {
    FormField,
    LoginWith
  },
  data () {
    return {
      form: {
        email: undefined,
        password: undefined
      },

      error: ''
    }
  },
  computed: {
    formData () {
      return {
        email: this.form.email,
        password: this.form.password
      }
    }
  },
  methods: {
    submit () {
      let form = this.formData
      this.$sdk.auth.login(form).then(response => {
        this.error = ''

        if (response.data.result) {
          this.$sdk.user.me().then(() => {
            this.$router.push({name: 'AccountCampaigns'})
          })
        } else {
          this.error = this.$t("login-modal.'Email' or 'Password' does not match")
          setTimeout(() => {
            this.error = ''
          }, 5000)
        }
      })
    }
  }
}
</script>
