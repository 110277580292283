<template>
  <div class="login-with-wrp">
    <div class="form-group">
      <div class="control-label">{{ label }}</div>

      <div class="login-with">
        <button type="button" @click="login('facebook')"><img src="/images/socials/facebook.svg"></button>
        <button type="button" @click="login('google')"><img src="/images/socials/gmail.svg"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginWith',
  props: {
    label: {
      type: String,
      default () {
        return this.$t('login_with.Or continue with following options:')
      }
    }
  },
  asyncComputed: {
    clients () {
      return this.$sdk.auth.clients()
        .then(response => response.data)
    }
  },
  methods: {
    login (name) {
      if (this.clients[name]) {
        window.location.href = this.clients[name]
      }
    }
  }
}
</script>

<style lang="less">
.login-with {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  button {
    border: 1px solid rgba(49, 55, 69, 0.13);
    border-radius: 9px;
    background-color: #FFFFFF;
    padding: 14px;
    box-sizing: border-box;

    width: 100%;

    &:hover {
      cursor: pointer;
      border-color: #0182FF;
      box-shadow: 0 11px 13px 0 rgba(45, 67, 121, 0.13);
    }
  }

  &-wrp {
    text-align: left;
  }
}

@media (max-width: 1000px) {
  .login-with-wrp {
    text-align: center;
    margin-top: 30px;

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
