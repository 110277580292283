<template>
  <div class="comment" @click="$emit('click')">
    <div class="comment-reply" v-if="comment.canReply" @click="$emit('reply')">
        <icon-reply color="#7483A9"/>
    </div>
    <div class="comment-image">
        <img :srcset="avatar">
    </div>
    <div class="comment-date">{{ comment.created_at | moment("DD MMMM YYYY, HH:ss")  }}</div>
    <div class="comment-title">{{ username }}</div>
    <div class="comment-text">{{ comment.text }}</div>
    <slot v-bind:children="comment.children"/>
  </div>
</template>

<script>
import IconReply from '@/assets/icons/IconReply'

export default {
  name: 'CampaignCommentItem',
  components: {
    IconReply
  },
  props: {
    comment: {
      type: Object
    }
  },
  computed: {
    avatar() {
      if (this.comment.author) {
        let str = this.comment.author.avatarUrl
        str = str.replace('{w}', 90)
        str = str.replace('{h}', 90)

        return str
      }

      return ''
    },
    username () {
      return this.comment.author ? this.comment.author.user_name : ''
    }
  }
}
</script>
