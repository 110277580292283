<template>
  <div class="content">
    <div class="campaign-tab-content">
      <ul class="campaign-tabs mini center-block">
        <router-link tag="li" :to="{name: 'MyCampaignEdit'}" v-if="$route.name === 'MyCampaignEdit'" active-class="active"><span>{{ $t('campaign-tabs.tab-edit-title') }}</span></router-link>
        <router-link tag="li" :to="{name: 'MyCampaignShow'}" v-else active-class="active"><span>{{ $t('campaign-tabs.tab-about-title') }}</span></router-link>
        <router-link tag="li" :to="{name: 'MyCampaignNews'}" active-class="active"><span>{{ $t('campaign-tabs.tab-news-title') }}</span></router-link>
        <router-link tag="li" :to="{name: 'MyCampaignComments'}" active-class="active"><span>{{ $t('campaign-tabs.tab-comments-title') }}</span></router-link>
      </ul>

      <transition name="fade">
        <router-view v-if="campaign" :campaign="campaign"/>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignTabs',
  asyncComputed: {
    campaign: {
      get () {
        let id = this.$route.params.id
        if (id) {
          return this.$sdk.campaign.view(this.$route.params.id, {
            expand: ['author', 'category'].join(',')
          }).then(response => response.data)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.campaign-tab-content {
  max-width: 680px;
  width: 100%;
  margin: 60px auto 0;

  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;

  box-sizing: border-box;
}
</style>
