import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import AsyncComputed from 'vue-async-computed'
import router from './router'
import VScrollLock from 'v-scroll-lock'
import vSelect from 'vue-select'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import Toasted from 'vue-toasted';

import sdk from '@/components/sdk'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueSocialSharing from 'vue-social-sharing'
import moment from 'vue-moment'
import vClickOutside from 'vue-click-outside'
import Vue2TouchEvents from 'vue2-touch-events'
import {i18n} from './i18n'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(moment)
Vue.use(VueSocialSharing, {
  networks: {
    messenger: 'https://www.facebook.com/dialog/send?link=@url&app_id=808027166425480'
  }
});
Vue.use(Vue2TouchEvents)
Vue.use(VueClipboard)
Vue.use(vueNumeralFilterInstaller)
Vue.use(AsyncComputed)
Vue.use(VScrollLock)
Vue.use(sdk)
Vue.use(Toasted, {
  theme: 'getdonate',
  duration: 7000,
  closeOnSwipe: false,
  position: 'bottom-center',
  action: [
    {
      class: 'close',
      onClick : (e, toastObject) => {
        toastObject.goAway(0);
      }
    }
  ]
})

Vue.component('v-select', vSelect)

Vue.directive('click-outside', vClickOutside)

Vue.sdk.init().then(() => {
  window.Vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
