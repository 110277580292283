export default {
  state: {
    languages: []
  },
  mutations: {
    'setLanguagesList' (state, data) {
      state.languages = data
    }
  },
  actions: {
    setLanguages ({ commit }, data) {
      commit('setLanguagesList', data)
    }
  },
  getters: {
    getLanguagesList: state => state.languages,
    getActiveLanguage: state => state.languages.find(item => item.isActive)
  }
}
