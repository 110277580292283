<template>
  <div class="block-stripe" :class="{bordered: bordered}">
    <img src="/images/stripe.svg" alt="" class="block-stripe-logo">

    <div v-if="!isStripeConnected">
      <ul>
        <li>{{ $t('stripe.first_line_text') }}</li>
        <li>{{ $t('stripe.second_line_text') }}</li>
      </ul>

      <div class="text-center" style="margin-top: 30px; margin-bottom: 30px;">
        <button class="btn btn-primary" @click="linkStripe">{{ $t('stripe.connect_with_stripe') }}</button>
        <div class="block-stripe-btn-desc">{{ $t('stripe.stripe_is_not_connected') }}</div>
      </div>
    </div>
    <div v-else style="margin: 30px 0">
      <div class="text-center" style="margin-top: 30px; margin-bottom: 30px;">
        <div style="margin-bottom: 30px; line-height: 1.65em;" v-html="$t('stripe.disconnect_message')"></div>
        <button class="btn btn-default btn-unlink" @click="unlinkStripe">
          <span>{{ $t('stripe.stripe_connected') }}</span>
          <span>{{ $t('stripe.disconnect_stripe') }}</span>
        </button>
      </div>
    </div>

    <div class="block-stripe-down-text">{{ $t('stripe.have_a_question') }} <router-link :to="{name: 'Page', params:{slug: 'stripe'}}">{{ $t('stripe.see_our_faq') }}</router-link></div>
  </div>
</template>

<style lang="less" scoped>

.block-stripe {
  color: #2D4379;
  padding: 31px;

  .block-stripe-logo {
    display: block;
    margin: 0 auto;
    width: 146px;
  }

  ul {
    padding-left: 20px;

    li {
      margin: 15px;
      font-size: 16px;
      line-height: 22px;
      position: relative;
      color: #2D4379;

      //list-style-type: disc;

      &:before {
        content: '\2022';
        font-size: 28px;
        position: absolute;
        left: -25px;
        color: #7483A9;
      }
    }
  }

  .block-stripe-btn-desc {
    color: #7483A9;
    font-size: 12px;
    //font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 18px;
  }

  .block-stripe-down-text {
    margin-top: 30px;
    font-size: 16px;
    text-align: center;

    a {
      font-size: 16px;
    }
  }

  .btn {
    &.btn-unlink {

      span {
        display: none;

        &:first-child {
          display: block;
        }
      }

      &:hover {
        background: #FF4C54;
        color: #fff;

        span {
          display: none;

          &:last-child {
            display: block;
          }
        }
      }
    }
  }

  &.bordered {
    border: 1px solid rgba(45,67,121,0.13);
    border-radius: 13px;

    @media (max-width: 1000px) {
      border: none;
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Stripe',
  props: {
    bordered: {
      type: Boolean,
      default: false
    }
  },
  asyncComputed: {
    clients() {
      return this.$sdk.auth.clients()
          .then(response => response.data)
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isStripeConnected () {
      return this.user && this.user.social && this.user.social.stripe
    }
  },
  methods: {
    resolveUrl (route) {
      return window.location.origin + this.$router.resolve(route).href
    },
    linkStripe () {
      window.location.href = this.clients.stripe
    },
    unlinkStripe () {
      let mess = this.$t('stripe.dialog_ask_question')
      this.$toasted.info('<div>' + mess + '</div>', {
        theme: 'dialog',
        duration: false,
        action: [
          {
            text: this.$t('stripe.cancel-button-text'),
            class: 'cancel',
            onClick : (e, toastObject) => {
              toastObject.goAway(0);
            }
          },
          {
            text: this.$t('stripe.continue-button-text'),
            class: 'continue',
            onClick : (e, toastObject) => {
              toastObject.goAway(0);

              this.$sdk.auth.socialDelete('stripe').then(resp => {
                this.$sdk.user.me()

                let mess = this.$t('stripe.You have successfully disconnect {name}', {name: name})

                this.$toasted.info(mess)
                return resp
              })
            }
          }
        ]
      })
    }
  }
}
</script>
