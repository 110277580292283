export default {
  state: {
    initialized: false
  },
  mutations: {
    'initialize' (state, data) {
      state.initialized = data
    }
  },
  actions: {
    setInitialized ({ commit }, data) {
      commit('initialize', data)
    }
  }
}
