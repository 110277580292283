<template>
  <form @submit.prevent="submit" class="question-form">
    <form-field label="Name" :error="errors.name" :value="form.name">
      <input type="text" :placeholder="$t('question-form.name-placeholder')" @keyup="validate(['name'])" v-model="form.name" class="form-control">
    </form-field>

    <form-field label="Email" :error="errors.email" :value="form.email">
      <input type="text" :placeholder="$t('question-form.email-placeholder')" @keyup="validate(['email'])" v-model="form.email" class="form-control">
    </form-field>

    <form-field label="Message" :error="errors.message" :value="form.message">
      <textarea :placeholder="$t('question-form.message-placeholder')" @keyup="validate(['message'])" v-model="form.message" class="form-control question-form-textarea" />
    </form-field>

    <div class="text-center">
      <button type="submit" class="btn btn-primary">{{ $t('question-form.button-text') }}</button>
    </div>

  </form>
</template>

<script>
import FormField from '@/components/FormField';
import { mapState } from 'vuex'

export default {
  name: 'QuestionForm',
  props: {
    /**
     * Campaign id
     */
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    FormField
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },

      errors: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  created () {
    if (this.$store.getters.isUser) {
      this.form.email = this.user.email
      this.form.name = this.user.user_name
    }
  },
  methods: {
    validate (attrs = []) {
      this.$sdk.question
        .create(this.id, this.form, true)
        .then(response => {
          for (let attribute of attrs) {
            if (response.data[attribute]) {
              this.$set(this.errors, attribute, response.data[attribute])
            } else {
              this.$delete(this.errors, attribute)
            }
          }
        })
    },
    submit () {
      this.$sdk.question
        .create(this.id, this.form, false)
        .then(response => {
          if (Array.isArray(response.data)) {
            const mess = this.$t('question-form.Message sent successfully')
            this.$toasted.info(mess)
            this.$router.push({
              name: 'Campaign',
              params: {
                id: this.id
              }
            })
          } else {
            this.errors = response.data
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.question-form {
  margin-top: 30px;

  &-textarea {
    min-width: 100%;
    max-width: 100%;

    min-height: 110px;
  }
}
</style>
