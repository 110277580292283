<template>
  <div class="container" style="min-height: 500px">

    <div class="campaign-view-wrap" v-if="campaign">
      <div class="campaign campaign-view">
        <div class="campaign-category">
          <span :class="getCampaignMarkerColor(campaign)">{{ getCampaignMarkerLabel(campaign) }}</span>
        </div>

        <h1 class="campaign-title">{{ campaign.title }}</h1>
        <p class="campaign-description">
          {{ campaign.short_description }}
        </p>

        <div class="campaign-numbers">
          <div class="campaign-numbers-left"><b>{{ campaign.collected_amount | numeral }}</b> EUR</div>
          <div class="campaign-numbers-right" v-html="$t('campaign.Raised of <b>{count}</b> {currency}', {count: $options.filters.numeral(campaign.required_amount), currency: 'EUR' })" />
        </div>
        <div class="campaign-timeline">
          <div class="campaign-timeline-bar" :style="{width: progressLineWidth + '%'}"></div>
        </div>

        <div class="campaign-author bordered" v-if="campaign.author">
          <div class="campaign-author-image">
            <img :src="getImageUrl(campaign.author.avatarUrl, 90, 90)"/>
          </div>
          <div class="campaign-author-name">
            {{ campaign.author.user_name }}
          </div>
          <div class="campaign-author-count">
            <router-link :to="{name: 'CampaignPayments', params: {id: campaign.id}}" tag="a">
              {{ $tc('campaign.{count} payments', campaign.payment_count) }}
            </router-link>
          </div>
          <div class="campaing-author-social socials-block" v-if="campaign.author.social_links">
            <a v-for="link in campaign.author.social_links" v-bind:key="link" :class="detectSocialNetwork(link)"
               target="_blank" :href="link"></a>
          </div>
        </div>

        <img :alt="campaign.title"
             :src="getImageUrl(campaignCover, 650, 450)"
             :srcset="getImageUrl(campaignCover, 1300, 900) + ' 2x'"
             class="campaign-image"
        >

        <div class="block-donate" v-if="campaign.permission.canAcceptPayment">
          <router-link tag="button" :to="{name: 'CampaignPay', params: {id: campaign.id}}" class="btn btn-primary btn-lg btn-block">{{ $t('campaign.donate-button-text') }}</router-link>
        </div>

        <ul class="campaign-tabs">
          <router-link tag="li" :to="{name: 'Campaign'}" :exact="true" active-class="active">
            <span>{{ $t('campaign.tab-about') }}</span>
          </router-link>
          <router-link tag="li" :to="{name: 'CampaignNews', params: {id: campaign.id}}" active-class="active">
            <span>{{ $t('campaign.tab-news') }} <i class="marker">{{ newsCount }}</i></span>
          </router-link>
          <router-link tag="li" :to="{name: 'CampaignComments', params: {id: campaign.id}}" active-class="active">
            <span>{{ $t('campaign.tab-comments') }} <i class="marker">{{ commentsCount }}</i></span>
          </router-link>
          <router-link tag="li" :to="{name: 'CampaignQuestions', params: {id: campaign.id}}" active-class="active">
            <span>{{ $t('campaign.tab-questions') }}</span>
          </router-link>
        </ul>

        <div class="campaign-text" v-show="$route.name === 'Campaign'">
          {{ campaign.description }}
        </div>

        <div class="campaign-news" v-show="$route.name === 'CampaignNews'">
          <campaign-news :campaign="campaign" @update-news-count="updateNewsCount"/>
        </div>

        <div class="campaign-comments" v-show="$route.name === 'CampaignComments'">
          <campaign-comment :campaign="campaign" @update-comments-count="updateCommentsCount" />
        </div>

        <div class="campaign-questions" v-show="$route.name === 'CampaignQuestions'">
          <question-form :id="campaign.id"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import QuestionForm from "@/views/campaign/questions/QuestionForm";
import CampaignNews from "@/views/campaign/news/CampaignNews";
import CampaignComment from "@/views/campaign/comments/CampaignComment";
import CampaignMixin from "@/components/mixins/CampaignMixin";

export default {
  name: 'Campaign',
  mixins: [CampaignMixin],
  components: {
    CampaignComment,
    QuestionForm,
    CampaignNews
  },
  metaInfo () {
    if (this.campaign) {
      return {
        title: this.campaign.title,
        description: this.campaign.short_description,
        image: this.getImageUrl(this.campaignCover, 650, 450)
      }
    }

    return {}
  },
  data () {
    return {
      activeTab: 'about',
      newsCount: 0,
      commentsCount: 0
    }
  },
  computed: {
    progressLineWidth () {
      return (100 * this.campaign.collected_amount) / this.campaign.required_amount
    },
    campaignCover () {
      return Array.isArray(this.campaign.images) && this.campaign.images.length > 0
          ? this.campaign.images[0]
          : ''
    }
  },
  asyncComputed: {
    campaign () {
      let id = this.$route.params.id
      if (id) {
        return this.$sdk.campaign.view(this.$route.params.id, {
          expand: 'author,category'
        }).then(response => response.data)
      }
    }
  },
  created() {
    if (this.$route.meta.noty) {
      this.$toasted.info(this.$route.meta.noty)
      this.$router.push({name: 'Campaign', params: {id: this.$route.params.id}})
    }
  },
  methods: {
    getImageUrl(url, width, height) {
      url = url.replace('{w}', width)
      url = url.replace('{h}', height)

      return url
    },

    detectSocialNetwork(url) {

      let links = {
        facebook: /.*(facebook).*/,
        vk: /.*(vk\.com).*/,
        google: /.*(google).*/,
        messenger: /.*(messenger).*/,
        ok: /.*(ok\.ru|odnoklassniki).*/,
        telegram: /.*(telegram|t\.me).*/,
        twitter: /.*(twitter\.com|t\.co).*/,
        viber: /.*(viber).*/,
        whatsapp: /.*(whatsapp).*/,
        instagram: /.*(instagram).*/,
        linkedin: /.*(linkedin).*/,
      }

      for (let social in links) {
        if (links[social].test(url)) {
          return social
        }
      }

      return 'unknown'
    },

    updateNewsCount(val) {
      this.newsCount = val
    },

    updateCommentsCount (val) {
      this.commentsCount = val
    }
  }
}
</script>

<style lang="less">
.block-donate {
  margin-top: 30px;

  &-error {
    padding: 30px;
    background: #fff8f8;
    border-radius: 13px;
    line-height: 1.85em;
    color: #7483a9;
    box-shadow: 0 11px 13px 0 rgba(45, 67, 121, 0.13);
    text-align: center;
  }
}
</style>
