<template>

  <transition name="fade">
    <div class="vm--container" v-show="$route.meta.modal">
      <div class="vm--overlay"></div>
      <component :is="$route.meta.modal" v-on:close="close" class="vm--modal"></component>
    </div>
  </transition>

</template>
<script>
import Login from '@/views/modals/Login';
import ResetPassword from '@/views/modals/ResetPassword';
import Registration from '@/views/modals/Registration';
import SetPassword from '@/views/modals/SetPassword';
import Share from '@/views/modals/Share';
import Payments from '@/views/modals/Payments'
import RegistrationSuccess from "@/views/modals/RegistrationSuccess";
import CampaignFilter from "@/views/modals/CampaignFilter";
import StripeModal from '@/views/modals/StripeModal';
import CampaignPay from '@/views/modals/CampaignPay';

export default {
  name: 'ModalContainer',
  components: {
    Login,
    ResetPassword,
    Registration,
    SetPassword,
    Share,
    Payments,
    RegistrationSuccess,
    CampaignFilter,
    StripeModal,
    CampaignPay
  },
  methods: {
    close () {
      this.$router.push({name: 'Home'})
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
