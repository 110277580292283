<template>
  <div class="campaign-view-wrap">
    <div class="campaign campaign-view" v-if="campaign">
      <div class="campaign-category">
        <span :class="getCampaignMarkerColor(campaign)">{{ getCampaignMarkerLabel(campaign) }}</span>
      </div>

      <h1 class="campaign-title">{{ campaign.title }}</h1>
      <p class="campaign-description">
        {{ campaign.short_description }}
      </p>

      <div class="campaign-numbers">
        <div class="campaign-numbers-left"><b>{{ campaign.collected_amount | numeral }}</b> EUR</div>
        <div class="campaign-numbers-right" v-html="$t('campaign.Raised of <b>{count}</b> {currency}', {count: $options.filters.numeral(campaign.required_amount), currency: 'EUR' })"/>
      </div>
      <div class="campaign-timeline">
        <div class="campaign-timeline-bar"
             :style="{width: (100 * campaign.collected_amount) / campaign.required_amount + '%'}"></div>
      </div>

      <img :alt="campaign.title"
           :src="getImageUrl(campaign.images[0], 650, 450)"
           :srcset="getImageUrl(campaign.images[0], 1300, 900) + ' 2x'"
           class="campaign-image"
      >

      <div class="campaign-text">
        {{ campaign.description }}
      </div>

      <div class="campaign-actions" :class="{'draft': isDraft}">
        <router-link tag="button" :to="{name: 'MyCampaignEdit'}" class="btn btn-primary btn-lg btn-block">
          <span>{{ $t('campaign.edit-campaign-button') }}</span>
        </router-link>
        <button v-if="isDraft && !isStripeIntegrated" class="btn btn-primary btn-lg btn-block" @click="publishCampaign">{{ $t('campaign.start-campaign-button') }}</button>
        <button v-else-if="isDraft && isStripeIntegrated" class="btn btn-primary btn-lg btn-block" @click="publishCampaign">{{ $t('campaign.publish-campaign-button') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CampaignMixin from '@/components/mixins/CampaignMixin'

export default {
  name: 'CampaignShow',
  mixins: [
    CampaignMixin
  ],
  props: {
    campaign: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isDraft () {
      return ['DRAFT', 'WAITING'].indexOf(this.campaign.status) > -1
    },
    isStripeIntegrated () {
      return this.campaign.permission.canPublish
    }
  },
  methods: {
    getImageUrl (url, width, height) {
      url = url.replace('{w}', width)
      url = url.replace('{h}', height)

      return url
    },
    publishCampaign () {
      if (!this.isStripeIntegrated) {
        let mess = this.$t("campaign.connect-stripe-message")
        this.$toasted.info(mess)
      }

      this.$sdk.user.me().then(() => {
        this.$sdk.campaign.publish(this.campaign.id)
          .then(() => {
            this.refreshCampaign()

            let mess = this.$t('campaign.campaign-has-started')
            this.$toasted.info(mess)
          })
          .catch(() => {
            this.$router.push({
              name: 'CampaignAboutWithStripeModal',
              params: {
                id: this.campaign.id
              }
            })
          })
      })
    },
    refreshCampaign () {
      this.$sdk.campaign.view(this.campaign.id).then(response => {
        this.campaign.status = response.data.status
      })
    }
  }
}
</script>

<style lang="less" scoped>
.campaign-actions {
  margin-top: 30px;

  &.draft {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 3fr;
  }
}
</style>
