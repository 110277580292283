<template>
  <div class="form-group langSwitcher">
    <v-select v-model="currentLanguage" :options="languages" @option:selected="changeLanguage" :searchable="false" :noDrop="false" :clearable="false" class="form-control" label="name"/>
  </div>
</template>

<script>
import {setLanguage} from "@/i18n";
import {mapGetters} from "vuex";

export default {
  name: 'LanguageSwitcher',
  data () {
    return {
      currentLanguage: null,
    }
  },
  computed: {
    ...mapGetters({
      activeLanguage: 'getActiveLanguage',
      languages: 'getLanguagesList'
    })
  },
  mounted() {
    this.$sdk.translate.languages().then(() => {
      this.currentLanguage = this.activeLanguage
    })
  },
  methods: {
    changeLanguage () {
      const lang_id = this.currentLanguage.id
      const lang_pm = this.$route.params.lang

      setLanguage(lang_id).then(() => {
        if (lang_pm !== lang_id) {
          const currentRoute = this.$route
          currentRoute.params.lang = lang_id

          this.$router.push(currentRoute)
        }
      })
    }
  }
}
</script>

<style lang="less">
.form-group.langSwitcher {
  margin-left: auto;
  margin-bottom: 0;
  width: 110px;

  .form-control {
    background: #fff;
    border-radius: 15px;
  }

  .vs__dropdown-menu {
    min-width: initial;
  }
}
</style>
