<template>
  <div>
    <div slot="top-right">
      <img src="/images/icons_svg/close.svg" alt="close" @click="$emit('close')" class="vm--close">
    </div>

    <form class="registration-form" v-scroll-lock="true" @submit.prevent="submit">
      <h1 class="registration-form-title color-dark-blue">{{ $t('registration-modal.title') }}</h1>
      <h4 class="registration-form-description color-text-desc">{{ $t('registration-modal.Already registered?') }} <router-link :to="{name: 'Login'}">{{ $t('registration-modal.Login') }}</router-link></h4>

      <div class="registration-form-content">
        <div class="left-block">

          <form-field :label="$t('registration-modal.email-label')" :error="errors.email" :value="form.email">
            <input v-model="form.email" @keyup="validate(['email'])" type="text" class="form-control" :placeholder="$t('registration-modal.email-placeholder')"/>
          </form-field>

          <form-field :label="$t('registration-modal.password-label')" :error="errors.password" :value="form.password">
            <input v-model="form.password" @keyup="validate(['password'])" type="password" class="form-control" :placeholder="$t('registration-modal.password-placeholder')"/>
          </form-field>

          <form-field :label="$t('registration-modal.confirm-label')" :error="errors.password_repeat" :value="form.password_repeat">
            <input v-model="form.password_repeat" @keyup="validate(['password_repeat', 'password'])" type="password" class="form-control" :placeholder="$t('registration-modal.confirm-placeholder')"/>
          </form-field>

          <form-field :error="errors.accept_terms_of_use" :value="form.accept_terms_of_use">
            <div class="checkbox">
              <input type="checkbox" id="agree1-checkbox" @change="validate(['accept_terms_of_use'])" v-model="form.accept_terms_of_use">
              <label for="agree1-checkbox">
                <img src="/images/icons_svg/checkbox.svg">
              </label>
              <label class="checkbox-text" for="agree1-checkbox">{{ $t('registration-modal.By signing up you accept') }} <router-link :to="{name: 'Page', params: {slug: 'agreement'}}">{{ $t('registration-modal.Terms of use') }}</router-link></label>
            </div>
          </form-field>

          <form-field :error="errors.consent_personal_data" :value="form.consent_personal_data">
            <div class="checkbox">
              <input type="checkbox" id="agree2-checkbox" @change="validate(['consent_personal_data'])" v-model="form.consent_personal_data">
              <label for="agree2-checkbox">
                <img src="/images/icons_svg/checkbox.svg">
              </label>
              <label class="checkbox-text" for="agree2-checkbox"> {{ $t('registration-modal.I confirm that I have read and accepted the') }}
                <router-link :to="{name: 'Page', params: {slug: 'privacy-policy'}}">{{ $t('registration-modal.Getdonate Privacy Policy') }}</router-link>
              </label>
            </div>
          </form-field>

          <button class="btn btn-primary" :disabled="isSubmitEnabled">{{ $t('registration-modal.sign-up-button-text') }}</button>
        </div>
        <div class="right-block hidden-xs">
          <login-with />
        </div>
      </div>

      <div class="other hidden-xs">
        {{ $t('registration-modal.Any questions?') }} <a href="mailto:info@getdonate.org">{{ $t('registration-modal.Contact right now') }}</a>
      </div>

    </form>

    <div class="visible-xs">
      <login-with />
    </div>
  </div>
</template>

<script>
import FormField from '@/components/FormField';
import LoginWith from "@/views/blocks/LoginWith";

export default {
  name: 'Registration',
  components: {
    FormField,
    LoginWith
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        password_repeat: '',
        accept_terms_of_use: false,
        consent_personal_data: false
      },

      errors: {}
    }
  },
  computed: {
    isSubmitEnabled () {
      return !this.form.password
          || !this.form.password_repeat
          || !this.form.email
          || !this.form.accept_terms_of_use
          || !this.form.consent_personal_data
    }
  },
  methods: {
    validate (attributes = []) {
      this.$sdk.user.create(this.form, true).then(response => {
        for (let attribute of attributes) {
          if (response.data[attribute] !== undefined) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    },
    submit () {
      this.$sdk.user.create(this.form, false).then(response => {
        if (Array.isArray(response.data)) {
          this.$router.push({name: 'RegistrationSuccess'})
        } else {
          this.errors = response.data
        }
      })
    }
  }
}
</script>
