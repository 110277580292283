<template v-if="initialized">
  <div class="wrapper">

    <div class="header-wrap hidden-xs">
      <div class="container">
        <div class="header">
          <router-link :to="{name: 'Home'}" class="logo" tag="span"></router-link>

          <language-switcher/>

          <ul class="header-menu" v-if="!$store.getters.isUser">
            <li><router-link :to="{name: 'Login'}" tag="button" class="btn btn-default">{{ $t('header.sign in') }}</router-link></li>
            <li v-if="isShareRoute">
              <router-link :to="{name: 'CampaignShare', params: {id: $route.params.id}}" tag="button" class="btn btn-primary">{{ $t('header.share') }}</router-link>
            </li>
            <li v-else>
              <router-link :to="{name: 'Registration'}" tag="button" class="btn btn-primary">{{ $t('header.raise funds') }}</router-link>
            </li>
          </ul>
          <ul class="header-menu" v-else>
            <li>
              <button v-if="isAccountPage" class="btn btn-default" @click="logout()">{{ $t('header.logout') }}</button>
              <router-link v-else tag="button" :to="{name: 'AccountCampaigns'}" class="btn btn-default">{{ $t('header.account') }}</router-link>
            </li>
            <li v-if="isShareRoute">
              <router-link :to="{name: 'CampaignShare', params: {id: $route.params.id}}" tag="button" class="btn btn-primary">{{ $t('header.share') }}</router-link>
            </li>
            <li v-else>
              <router-link :to="{name: 'CampaignCreate'}" tag="button" class="btn btn-primary">{{ $t('header.raise funds') }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="header-wrap visible-xs">
      <div class="container">
        <div class="header mobile visible-xs">
          <div class="icon-burger" @click.prevent="showMobileMenu()"></div>

          <router-link :to="{name: 'Home'}" class="logo" tag="span"></router-link>

          <router-link v-if="isShareRoute" :to="{ name: 'CampaignShare', params: {id: $route.params.id}}" tag="span">
            <icon-share color="#2D4379"/>
          </router-link>
        </div>

        <transition name="slide-fade">
          <div class="menu-mobile-wrap" v-if="mobileMenuVisible">
            <div class="menu-mobile-overlay" @click="hideMobileMenu"></div>
            <div class="menu-mobile" v-scroll-lock="true" v-touch:swipe.left="hideMobileMenu">
              <div class="menu-mobile-header" v-if="!$store.getters.isUser">
                <router-link :to="{name: 'Home'}" class="logo" tag="span"></router-link>
                <router-link :to="{name: 'Login'}" tag="button" class="btn btn-white">{{ $t('header.sign in') }}</router-link>
              </div>
              <div class="menu-mobile-header" v-else>
                <div class="menu-mobile-avatar"><img :src="getImageUrl(user.avatarUrl, 55, 55)" :srcset="getImageUrl(user.avatarUrl, 110, 110) + ' 2x'" /></div>
                <button v-if="isAccountPage" class="btn btn-white" @click="logout()">{{ $t('header.logout') }}</button>
                <router-link v-else tag="button" :to="{name: 'AccountCampaigns'}" class="btn btn-white">{{ $t('header.account') }}</router-link>
              </div>
              <div style="padding: 15px; border-bottom: 1px solid rgba(45, 67, 121, 0.1);">
                <language-switcher style="width: 100%"/>
              </div>
              <ul class="menu-mobile-ul">
                <li><router-link :to="{name: 'Page', params: {slug: 'about-us'}}">{{ $t('footer.about us') }}</router-link></li>
                <li><router-link :to="{name: 'Page', params: {slug: 'how-it-works'}}">{{ $t('footer.how it works') }}</router-link></li>
                <li><router-link :to="{name: 'Page', params: {slug: 'our-advantages'}}">{{ $t('footer.our advantages') }}</router-link></li>
                <li><router-link :to="{name: 'Page', params: {slug: 'media'}}">{{ $t('footer.media') }}</router-link></li>

                <li><router-link :to="{name: 'Page', params: {slug: 'faq'}}">{{ $t('footer.faq') }}</router-link></li>
                <li><a href="mailto:info@getdonate.org">{{ $t('footer.contact us') }}</a></li>
                <li><router-link :to="{name: 'Page', params: {slug: 'service-fee'}}">{{ $t('footer.service fee') }}</router-link></li>
                <li><router-link :to="{name: 'Page', params: {slug: 'agreement'}}">{{ $t('footer.agreement') }}</router-link></li>
              </ul>
              <div class="menu-mobile-footer">
                <router-link tag="button" :to="{name: $store.getters.isUser ? 'CampaignCreate' : 'Registration'}" class="btn btn-primary btn-block">{{ $t('header.raise funds') }}</router-link>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>

    <footer class="footer hidden-xs">
      <div class="container">

        <div class="footer-wrap">
          <ul>
            <li><h4>{{ $t('footer.contacts') }}</h4></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'about-us'}}">{{ $t('footer.about us') }}</router-link></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'how-it-works'}}">{{ $t('footer.how it works') }}</router-link></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'our-advantages'}}">{{ $t('footer.our advantages') }}</router-link></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'media'}}">{{ $t('footer.media') }}</router-link></li>
          </ul>

          <ul>
            <li><h4>{{ $t('footer.support') }}</h4></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'faq'}}">{{ $t('footer.faq') }}</router-link></li>
            <li><a href="mailto:info@getdonate.org">{{ $t('footer.contact us') }}</a></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'service-fee'}}">{{ $t('footer.service fee') }}</router-link></li>
            <li><router-link :to="{name: 'Page', params: {slug: 'terms-of-use'}}">{{ $t('footer.terms of use') }}</router-link></li>
          </ul>

          <ul>
            <li><h4>{{ $t('footer.social') }}</h4></li>
            <li><a target="_blank" href="https://t.me/getdonate">Telegram</a></li>
            <li><a target="_blank" href="https://www.facebook.com/GetDonate-351888922667578">Facebook</a></li>
            <li><a target="_blank" href="https://www.instagram.com/getdonatehelp/">Instagram</a></li>
            <li><a target="_blank" href="https://twitter.com/DonateGet">Twitter</a></li>
          </ul>
        </div>

      </div>
    </footer>

    <modal-container/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ModalContainer from "@/views/modals/ModalContainer"
import IconShare from "@/assets/icons/IconShare"
import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
  name: 'App',
  components: {
    LanguageSwitcher,
    ModalContainer,
    IconShare
  },
  data () {
    return {
      mobileMenuVisible: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      filterItems: state => state.filter.items,
      initialized: state => state.init.initialized
    }),
    isShareRoute () {
      return /^\/([a-z]{2})\/campaign\//.test(this.$route.path)
        && this.$route.name !== 'CampaignCreate'
    },
    isAccountPage () {
      return /^\/([a-z]{2})\/account/.test(this.$route.path)
    }
  },
  watch: {
    '$route' () {
      this.hideMobileMenu()
    }
  },
  methods: {
    logout () {
      this.$sdk.auth.logout().then(() => {
        this.$router.push({name: 'Home'})
      })
    },
    showMobileMenu () {
      this.mobileMenuVisible = true
    },
    hideMobileMenu () {
      this.mobileMenuVisible = false
    },
    getImageUrl (url, width, height) {
      if (url) {
        url = url.replace('{w}', width)
        url = url.replace('{h}', height)

        return url
      }
    }
  }
}
</script>

<style lang="less" src="./assets/styles/main.less"></style>
<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  //transform: translateX(-100%);
  //background: transparent;
}
</style>
