<template>
  <div class="cp-filter">

    <div class="cp-filter-line">
      <button class="cp-filter-line-left" ref="arrowLeft" @click="moveLeft">
        <span>&#10094;</span>
      </button>

      <ul class="campaign-tabs" ref="line">
        <router-link tag="li" :to="{name: 'HomeFilter', params: {slug: item.id}}" v-for="item in categories" :key="'filter_' + item.id" :class="{'active': item.id === filterCategory}">
          <span :ref="'item_' + item.id">{{ item.name }}</span>
        </router-link>
      </ul>

      <button class="cp-filter-line-right" ref="arrowRight" @click="moveRight">
        <span>&#10095;</span>
      </button>
    </div>

    <div class="form-group" style="margin-bottom: 0">
      <v-select v-model="filterStatus" :options="statusList" :reduce="item => item.id" :searchable="false" :noDrop="false" :clearable="false" label="label" class="form-control statusSelector"/>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'CampaignsFilter',
  data () {
    return {
      line: null,
    }
  },
  computed: {
    ...mapState({
      categories: state => state.filter.categories,
      statusList: state => state.filter.statuses
    }),
    filterCategory () {
      return (this.$route.params['slug'] && (this.$route.params['slug'] !== 'all')) ? parseInt(this.$route.params['slug']) : 'all'
    },
    filterStatus: {
      get () {
        return this.$route.params['status'] ? this.$route.params['status'].toUpperCase() : 'ALL'
      },
      set (val) {
        this.$router.push({
          name: 'HomeFilter',
          params: {
            slug: this.filterCategory || 'all',
            status: val.toLowerCase()
          }
        })
      }
    }
  },
  updated() {
    this.scrollToActive()
  },
  mounted() {
    this.$sdk.category.find().then(response => {
      let items = [{
        id: 'all',
        name: this.$t('filter.All')
      }]

      items.push(...response.data)
      this.$store.dispatch('setFilterCategories', items)
    })

    this.$store.dispatch('setFilterStatusList', [
      {
        id: 'ALL',
        label: this.$t('filter.All')
      }, {
        id: 'NEW',
        label: this.$t('filter.New')
      }, {
        id: 'POPULAR',
        label: this.$t('filter.Popular')
      }, {
        id: 'FINISHED',
        label: this.$t('filter.Closed')
      }
    ])
  },
  methods: {
    moveLeft () {
      this.$refs.line.scrollLeft -= 100
    },
    moveRight () {
      this.$refs.line.scrollLeft += 100
    },
    scrollToActive () {
      let key = 'item_' + this.filterCategory

      if (this.$refs[key]) {
        let line = this.$refs.line
        let actv = this.$refs[key][0];

        let z = actv.offsetLeft - (line.clientWidth) / 2

        this.$refs.line.scrollTo({
          left: z,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>


<style lang="less">
.cp-filter {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 30px;
  grid-gap: 20px;

  &-line {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    position: relative;
    border-radius: 13px;

    button:first-child {
      border-radius: 13px 0 0 13px;
    }
    button:last-child {
      border-radius: 0 13px 13px 0;
    }

    &-left,
    &-right {
      width: 40px !important;
      background: #F8FAFF;
      color: #0182ff;
      outline: none;

      display: grid;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;

      span {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      &:disabled {
        color: #666
      }
    }

    &-left {
      left: 4px;
      top: 4px;
    }

    &-right {
      right: 4px;
      top: 4px;
    }

    .campaign-tabs {
      //padding-left: 30px;
      //padding-right: 30px;
      margin: 0;
      overflow-y: scroll;
      border-radius: 0;
    }
  }

  &-button {
    white-space: nowrap;
    padding-right: 0;

    @media all and (max-width: 767px) {
      display: none;
    }
  }
}

.form-control.statusSelector {
  width: 170px;
  background: #F8FAFF;
  border-radius: 13px;
  border: 1px solid #F8FAFF;

  &.vs--open {
    background-color: #FFF;
    border-radius: 13px 13px 0 0;
    border-bottom: 0;
    border-color: #0182FF;

    .vs__dropdown-menu {
      border-radius: 0 0 13px 13px;
    }
  }

  .vs__selected {
    font-weight: normal;
  }
}

@media all and (max-width: 767px) {
  .cp-filter {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
    margin-top: 80px;
  }

  .form-control.statusSelector {
    width: 100%;
    margin: auto;
  }
}
</style>
